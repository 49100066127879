footer {
  background: var(--green);
  color: var(--offwhite);
  min-height: 220px;
  margin-top: auto;
  padding: 20px;
  font-size: 1.6rem;
}

footer .footerSection {
  display: grid;
  grid-template-columns: 1fr 1fr;

  max-width: 1024px;
}

.footerSection h3,
.footerSection ul {
  margin-left: 20px;
  margin-bottom: 5px;
  color: var(--offwhite);
}

footer .footerSection ul li {
  display: block;
}

.footerSection .leftSection {
  grid-column: 1;
}

.footerSection .rightSection {
  grid-column: 2;
  grid-row: 1;
}

.footerSection .newsletter {
  grid-column: 1/3;
  grid-row: 2;
  margin-left: 20px;
  margin-top: 50px;
}

.newsletter input {
  padding: 5px;
  width: 55vw;
  height: 40px;
  max-width: 290px;
  font-size: 1.6rem;
  display: inline;
}

.newsletter button {
  border-radius: 0;
  min-width: 100px;
  margin-left: -5px;
  display: inline;
}

footer a,
footer a:visited {
  color: var(--nearwhite);
}

footer a:hover {
  color: var(--black);
}

@media screen and (min-width: 800px) {
}
