.datesContainer {
    margin-top: 20px;
    display: block;
    grid-row: auto;
    align-items: center;
    justify-content: center;
    gap: 20px;
    margin-bottom: 20px;
    border-bottom: solid 1px var(--lightgreen);
    padding-bottom: 20px;
    font-size: 1.4rem;
    overflow: auto;
    width: 100%;
}

.datesContainer svg {
    border: solid 2px var(--green);
    border-radius: 60px;
    padding: 10px;
}

.dateRange {
    text-align: center;
    /* white-space: nowrap; */
    white-space: nowrap;
    margin: auto;
    width: fit-content;
}


.dateRange ul {
    width: fit-content;
}

.dateRange ul li {

    color: var(--green);
    border: none;
    border-left: solid 1px var(--green);
    cursor: pointer;
    padding: 10px;
    margin: 0;
    width: fit-content;
    font-size: 2rem;
    min-width: 100px;
}

.dateRange ul li:hover {
    color: var(--red);
} 

.dateRange ul li:last-child {
    border-right: solid 1px var(--green);
}

.dateRange ul li span {
    font-weight: bold;
}


.dateRange ul li.selected {
    background: rgba(93, 105, 59, 1);
    color: var(--nearwhite);
    box-shadow: 0 0 10px #333;
}