.error {
  padding: 5px 12px;
  font-style: italic;
  width: fit-content;
  border-radius: 10px;
  background: var(--beige);
  color: var(--red);
  font-weight: bold;
  font-size: 2.1rem;
  border: solid 1px var(--red);
  height: auto;
  margin: 10px auto;
}

.inputerror::after {
  content: "";
  border: solid var(--beige);
  border-width: 0 15px 15px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
  position: relative;
  top: -15px;
  left: -50%;
}

.inputerror {
  top: 60px;
  left: 0;
  z-index: 999;
}

.input.error {
  border: 2px solid var(--red);
}

.signUpForm {
  flex-direction: column;
}

.signUpForm div {
  position: relative;
}

.signUpForm div.viewPassword {
  background: url(../../public/images/icons/eye-password-show-svgrepo-com.svg);
  background-repeat: no-repeat;
  background-size: contain;
  width: 25px;
  height: 25px;
  position: absolute;
  right: 25px;
  top: 35px;
  cursor: pointer;
  min-width: 00;
}

/* .signup {
   
    padding: 0;
    padding-top: 0px;
    height: 100vh;
    width: 100vw;
    margin-top: -60px;
    padding-top: 60px;

} */

input,
select,
textarea {
  max-width: 300px;
  width: 100%;
  border-radius: 10px;
  padding: 16px 10px;
  margin: 20px 0 20px;
  display: block;
  font-size: 1.75rem;
}

select {
  background: #fff;
}

.signup input[type="textarea"] {
  height: 100px;
  width: 90%;
  margin: 10px 0 20px;
  max-width: none;
}

.signUpForm div {
  width: fit-content;
  min-width: 300px;
  /* margin: auto; */
  max-width: 600px;
}
