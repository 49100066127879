@media screen and (min-width: 600px) {
  /* .dashboardPanel .mealCard {
        
        width: 45%;
        
    } */
}

@media screen and (min-width: 664px) {
  .mealCard {
    max-width: 300px;
    height: 350px;
  }

  .dashboardContainer .mealCard {
    max-width: 100%;
  }

  /* .mealCard .imgContainer {
          max-width: 300px;
        } */

  .chosenmeals {
    height: 67px;
  }
}

@media screen and (min-width: 720px) {
  .containerForm input,
  .deliveryForm input,
  .deliveryForm select {
    max-width: 60vw;
    margin-bottom: 5px;
    margin-right: 10px;
    border: solid 1px var(--green);
    border-radius: 10px;
  }

  .containerForm textarea {
    max-width: calc(80vw + 10px);
  }

  .deliveryForm textarea {
    max-width: calc(80vw + 50px);
    margin-bottom: 5px;
    margin-right: 10px;
    border: solid 1px var(--green);
    border-radius: 10px;
    width: 100%;
  }

  .dashboardContainer .mealCard {
    max-width: 360px;
  }
}

@media screen and (min-width: 1024px) {
  .mealtypes ul li:not(:last-child)::after {
    content: "";
    margin-right: 0;
  }
  h1 {
    font-size: 6rem;
  }

  h2 {
    font-size: 2.5rem;
  }

  h3,
  h4 {
    font-size: 2rem;
  }
  /* .dashboardContainer {
                    width: 100vw;
                    /* margin: 40px auto; */
  /* height: 100%;
                    padding: 0;
                } */

  .tab-content h2 {
    margin: 0 40px 0 0;
  }

  .userDashboard h1,
  .userContainer h1 {
    font-size: 6rem;
  }

  .hamburgerSection:not(.inslet) {
    justify-self: end;
  }

  .containerForm {
    display: flex;
    justify-content: center;
    align-items: stretch;

    height: 100%;

    flex-direction: row;
  }

  .headerSection {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
  }

  .headerSection h2 {
    grid-row: 2;
    margin-bottom: 60px;
  }

  .headerSection a {
    grid-column: 2;
    grid-row: 1;
    justify-self: end;
  }

  .containerForm h1 {
    font-size: 2.5em;
  }

  /* .containerForm div{
            padding-right: 40px;
        } */

  .containerForm .leftContents {
    position: relative;
    max-width: 700px;
    margin-left: auto;
    /* min-width: 600px; */
  }

  .containerForm .left {
    align-items: center;
    margin-left: auto;
    display: flex;
  }

  .containerForm .right {
    width: 100%;
    background: var(--nearwhite);
    display: flex;
    flex-wrap: wrap;
    align-content: center;
  }

  .containerForm form {
    width: auto;
    align-content: center;
  }

  /* .containerForm form button {
                    margin-top: 20px;
                 } */

  .containerForm form .message {
    width: 100%;
  }

  .containerForm form input,
  .containerForm form textarea,
  .deliveryForm input,
  .deliveryForm select {
    max-width: 460px;
  }

  .deliveryForm textarea {
    max-width: 920px;
  }

  .containerForm.payment .left {
    align-items: start;
    margin-top: 180px;
  }

  .containerForm svg,
  .payIcon {
    transform: rotate(20deg);
    opacity: 0.3;
    position: absolute;
    top: -161px;
    width: 163px;
    left: 254px;
  }

  .payIcon {
    transform: rotate(33deg);
    opacity: 0.3;
    position: absolute;
    top: -161px;
    width: 251px;
    left: -14px;
  }

  .userLinks {
    display: block;
  }
  .wrapper.signup {
    background-image: url(../../public/images/backgrounds/meals.svg);
    /* background-repeat: no-repeat;*/
    background-position: top left;
    background-size: 55%;
  }

  .midadgreen div {
    /* justify-self: end;
            max-width: calc(90rem / 2 + 55px); */
    padding: 3rem 2.5rem;
  }

  /* .accordion::before {
     content: '';
     background-color: var(--green);
     float: left;
     margin-left: 5px;
     margin-right: 19px;
     margin-top: -8px;
     width: 10px;
     height: 60px;

 } */

  .mealList {
    width: 100%;
    display: flex;
    gap: 20px;
    row-gap: 20px;
    /* grid-template-columns: repeat(6, 1fr); */
    row-gap: 24px;
    justify-items: center;
  }

  /* .mealImages {
            display: flex;
           
            position: fixed;
            top: calc(100vh - 110px);
            background: #fff;
            width: 100%;
            left: 0;
            padding: 20px;
            z-index: 99;
            justify-content: center;
            flex-wrap: wrap;
        } */

  .selectedText {
    color: var(--green);
    width: fit-content;
    text-align: auto;
    margin-bottom: 10px;
  }

  .mealImages .nextButton {
    margin: 20px 40px;
  }

  .mealsContainer {
    width: 960px;
  }
  /* .dashboardContainer .mealsContainer {
                     max-width: 760px;
                     width: calc(100vw - 300px);
                 } */

  .dashboardContainer .mealList {
    grid-template-columns: repeat(4, 1fr);
  }

  .dashboardContainer .mealCard {
    grid-column: auto/span 2;
  }

  .mealCard {
    display: flex;
    flex-direction: column;
    grid-column: auto/span 2;
    max-width: 320px;
    justify-content: stretch;
    border: solid 1px #ccc;
    border-radius: 0 0 20px 20px;
    border-radius: 20px;
    padding-bottom: 10px;
    height: 545px;
  }

  .checkoutMeals .mealCard {
    height: fit-content;
  }

  .mealDetails h4,
  .mealDetails p {
    margin: 0;
    font-size: 2rem;
  }

  .mealCard ul li.meat {
    background-image: url(../../public/images/icons/meat.png);
  }

  .mealCard ul li.vegatarian {
    background-image: url(../../public/images/icons/vegatarian.png);
  }

  .mealCard ul li.pescatarian {
    background-image: url(../../public/images/icons/pescatarian.png);
  }

  .mealCard ul li.gourmet {
    background-image: url(../../public/images/icons/gourmet.png);
  }

  .mealCard ul li.familyfriendly {
    background-image: url(../../public/images/icons/family.png);
  }

  .mealCard ul li.chicken {
    background-image: url(../../public/images/icons/chicken.png);
  }

  .mealCard ul li.kidfriendly {
    background-image: url(../../public/images/icons/kidfriendly.png);
  }

  .mealCard ul li.lowcarb {
    background-image: url(../../public/images/icons/lowcarb.png);
  }

  .mealCard ul li.caloriesmart {
    background-image: url(../../public/images/icons/lowcalorie.png);
  }

  .mealCard ul li.classics {
    background-image: url(../../public/images/icons/classics.png);
  }

  .mealCard ul li.guiltfree {
    background-image: url(../../public/images/icons/guiltfree.png);
  }

  .mealCard ul li.vegetarian {
    background-image: url(../../public/images/icons/vegetarian.png);
  }

  .mealCard ul li.breakfast {
    background-image: url(../../public/images/icons/breakfast.png);
  }

  .mealCard ul li {
    color: var(--green);
    text-transform: capitalize;
    padding-left: 32px;
    padding-top: 3px;
    background-repeat: no-repeat;
    background-size: 30px;
    background-position: left;
    height: 27px;
    width: 155px;
    margin-bottom: 10px;
    font-size: 1.6rem;
    border: solid 1px var(--lightgreen);
    border-radius: 20px;
  }

  .mealtypes ul li::after {
    content: "";
    display: block;
    clear: both;
  }

  .addRemove {
    width: 200px;
  }

  .addRemove button,
  .userDashboard .addRemove button {
    width: 200px;
  }

  .mealCard .mealDetails {
    height: fit-content;
    height: 100px;
  }

  .mealCard .mealtypes {
    height: 80px;
  }
  .addRemove {
    left: 0;
  }

  .dashboard .mealCard {
    width: 48%;
  }

  .mealCard .imgContainer {
    height: 250px;
    overflow: hidden;
    order: 0;
    width: 100%;
    min-height: 250px;
  }

  .mealCard.expanded .imgContainer {
    height: 410px;
  }

  .contentContainer {
    flex-grow: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-bottom: 20px;
  }

  .addRemove {
    margin: auto auto 0;
  }

  .mealCard img {
    border-radius: 20px 20px 0 0;
  }

  .mealDetails p {
    font-size: 1.4rem;
  }

  /* .mealimgContainer img {
                min-height: 345px;
           } */

  .mealCard ul li {
    color: var(--green);
    text-transform: capitalize;
    padding-left: 22px;
    padding-top: 3px;
    background-repeat: no-repeat;
    background-size: 22px;
    background-position: left;
    height: 27px;
    width: 40%;
    margin: 0 10px 5px 5px;
    font-size: 1.4rem;
    border: solid 1px var(--lightgreen);
    border-radius: 20px;
  }

  .checkboxes-group input[type="checkbox"] + label {
    height: 125px;
    min-width: 125px;
    display: inline-block;
    padding: 20px 20px 5px;
    text-align: center;
    place-content: end;
    margin: 0;
    text-transform: capitalize;
    color: var(--red);

    border: solid 1px #ccc;
    cursor: pointer;
    position: relative;
    background-repeat: no-repeat;
    background-size: 100px;
    background-position: top center;
    border-radius: 16px;
  }

  .checkboxes-group input[type="checkbox"]:checked + label {
    background-color: var(--green);

    color: var(--nearwhite);
    background-repeat: no-repeat;
    background-size: 100px;
    background-position: top center;
  }

  dialog.recipe {
    width: 70vw;
    padding: 0;
  }

  .recipeContent {
    display: grid;
    grid-template-columns: 70% 1fr;
    gap: 30px;
  }
  .recipeContent .before {
    grid-column: 1/-1;
    grid-row: 1;
  }
  .recipeContent .instructions {
    grid-column: 1;
    grid-row: 2/5;
  }

  .recipeContent .ingredients {
    grid-column: 2;
    grid-row: 2;
  }

  .recipeContent .allergens {
    grid-row: 3;
    grid-column: 2;
  }

  .recipeContent .nutrition {
    grid-column: 2;
    grid-row: 4;
  }

  .recipe .modalTop {
    display: flex;
    border-bottom: solid 1px gray;
    justify-content: end;
    background: #fff;
    border-radius: 20px 20px 0 0;
    padding: 10px;
    font-weight: bold;
    font-size: 1.6rem;
    margin-right: auto;
    position: sticky;
    top: 0px;
  }

  .recipe .topSection {
    position: sticky;
    top: 50px;
    background: #fff;
    padding: 20px;
  }

  .recipe .topSection h1 {
    font-size: 3.5rem;
  }

  .recipe .topSection h4 {
    color: var(--red);
  }

  .recipe .topSection p {
    margin-top: 20px;
    color: darkgray;
    display: block;
  }

  .recipe .modalBody {
    padding: 0;
  }

  .filters {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: center;
  }

  li.filterbutton {
    background-size: 35px;
    background-repeat: no-repeat;
    font-size: 1.6rem;
    border: solid 2px var(--green);
    border-radius: 50px;
    padding: 8px 10px 8px 37px;
    cursor: pointer;
    background-position: center left;
    text-transform: capitalize;
  }
  .userDashboard {
    display: flex;
    flex-wrap: wrap;
    /* gap: 40px; */
    padding-bottom: 0;
    /* grid-template-columns: repeat(auto-fill, minmax(1005px, 1fr));
    grid-auto-rows: minmax(150px, auto); */
    /* max-width: 80vw;
    margin: auto; */
  }

  .dashboardContent {
    width: 75%;
  }

  .userDashboard.mealsPanel {
    grid-row-end: span 2;
  }

  .userDashboard .rightPanel {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: auto;
  }

  .userDashboard .rightPanel .dashboardPanel {
    margin-bottom: 40px;
    margin-right: 20px;
    width: 21%;
    min-width: 300px;
  }
  .userPanel {
    display: block;
    width: 20%;
  }

  .leftPanel,
  .rightPanel {
    width: 100%;
  }

  .mealsContainer {
    margin: 0px;
  }

  /* .dashboardPanel a {
     margin-top: -50px;
     text-align: right;
 } */
}

@media screen and (min-width: 1280px) {
  .mealsContainer {
    width: 1200px;
  }

  .mealList {
    grid-template-columns: repeat(4, 1fr);
  }
  .mealCard {
    grid-column: auto/span 1;
  }

  .mealImages .nextButton {
    margin: 20px 100px;
  }

  /* .dashboardContainer .mealsContainer {
        max-width: 1050px;
        width: calc(100vw - 300px);
    } */

  .dashboardContainer .mealList {
    grid-template-columns: repeat(6, 1fr);
  }

  .dashboardContainer .mealCard {
    grid-column: auto/span 2;
  }
}

@media screen and (min-width: 1600px) {
  /* .mealCard {
        display: flex;
        flex-direction: column;
            width: fit-content;
        padding-bottom: 0;
        flex-grow: 1;
       

    } */

  .dashboardContainer .mealList {
    grid-template-columns: repeat(4, 1fr);
  }

  .dashboardContainer .mealCard {
    grid-column: auto/span 1;
  }

  dialog.recipe {
    width: 50vw;
    padding: 0;
  }
  /* .container {
            padding: 0 100px;
        } */

  .wrapper.boxes::before {
    /* background-size: 100vw;
      background-position: 180px -911px;
      overflow: auto; */
  }
}

@media screen and (min-width: 2080px) {
  .containerForm form textarea {
    max-width: 950px;
  }
}
