.containerForm {
  width: 100%;
  background: var(--beige);
}

.containerForm .left {
  position: relative;
  padding: 32px;
  color: #454444;
  width: 100%;
}

.containerForm .right {
  width: 100%;
  background: var(--nearwhite);
  padding: 20px;
}

.containerForm h2 {
  margin-bottom: 20px;
}

.slotError {
  padding: 5px 12px;
  font-style: italic;
  width: -moz-fit-content;
  width: fit-content;
  border-radius: 10px;
  background: var(--beige);
  color: var(--red);
  font-weight: bold;
  font-size: 2.1rem;
  border: solid 1px var(--red);
  height: auto;
  margin: 10px auto;
}

.slotError:empty {
  display: none;
}

.containerForm svg {
  transform: rotate(20deg);
  opacity: 0.15;
  position: absolute;
  top: 0px;
  width: 192px;
  right: 50px;
  position: absolute;
}

.payIcon {
  transform: rotate(20deg);
  opacity: 0.15;
  position: absolute;
  top: -29px;
  width: 206px;
  right: 40px;
}

.containerForm form {
  /* padding-left: 40px; */
  display: flex;
  flex-wrap: wrap;
  background: var(--nearwhite);
  width: auto;
  max-width: 1080px;
}

/* .containerForm form div {
    width: 100%;

} */

.containerForm input {
  max-width: 90vw;
  margin-bottom: 5px;
  border: solid 1px var(--green);
  border-radius: 10px;
}

.containerForm textarea {
  max-width: 80vw;
}

.containerForm .socialImgs img,
footer .socialImgs img {
  width: 20px;
  margin-right: 10px;
  position: relative;
  top: 4px;
}
footer .socialImgs {
  margin-left: 20px;
}
footer .newSVG {
  background: var(--beige);
}

.privacy {
  width: 100%;
  margin-left: -6px;
}

.privacy label,
.privacy input {
  width: fit-content;
  display: inline;
  margin-right: 1px;
  align-self: center;
  margin-bottom: 20px;
  font-size: 1.75rem;
}

.privacy label {
  position: relative;
  top: -2px;
  left: 5px;
}

.containerForm .privacy input {
  width: 25px;
  max-width: 25px;
  border: solid 1px var(--nearblack);
  border-radius: 15px;
  height: 20px;
  margin-bottom: 20px;
  margin-right: 5px;
}

.containerForm .error {
  display: none;
  margin: 20px auto;
  text-align: center;
}

.containerForm form .formbutton {
  margin: 20px auto;
  width: fit-content;
  min-width: fit-content;
}

.wrapper .error {
  margin: 20px 0;
}

/* delivery form */
.deliveryForm {
  position: relative;
  width: 100%;
}

.deliveryForm .left {
  position: relative;
  padding: 0;
}

.deliveryForm h2 {
  margin-top: 20px;
  width: 100%;
}

.deliveryForm .wwrapper h2 {
  margin-bottom: 20px;
}

.deliveryForm svg {
  transform: rotate(20deg);
  opacity: 0.15;
  position: absolute;
  top: 0px;
  width: 192px;
  right: 50px;
  position: absolute;
}

.deliveryForm form {
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  background: var(--nearwhite);
  width: 100%;
  flex-direction: column;
}

/* .deliveryForm form div {
                     width: 100%;
        
                 } */

.deliveryForm input,
.deliveryForm select,
.deliveryForm textarea {
  max-width: 80vw;
  margin-bottom: 5px;
  border: solid 1px var(--green);
  border-radius: 10px;
}

.deliveryForm textarea {
  max-width: 80vw;
}

.deliveryForm .socials object {
  width: 20px;
  margin-left: 10px;
  position: relative;
  top: 4px;
}

.deliveryForm .error,
.paymentForm .error {
  position: absolute;
  display: none;
  white-space: nowrap;
}

.deliveryForm .wwrapper {
  margin-top: 40px;
  border-top: solid 3px var(--green);
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.deliveryForm .deliveryDay {
  margin: 10px auto;
}

.deliveryForm .deliveryDay ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.deliveryForm .deliveryDay li {
  border: solid 1px var(--green);
  padding: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
  width: 20vw;
  text-align: center;
  min-width: 140px;
  max-width: 360px;
  font-size: 2rem;
  cursor: pointer;
}

.deliveryForm .deliveryDay li:hover {
  border: solid 1px var(--red);
}

.deliveryForm .deliveryDay li.active {
  background-color: var(--red);
  color: var(--nearwhite);
}

.deliveryForm .deliveryDay li.disabled {
  opacity: 0.5;
  pointer-events: none;
}
.deliveryForm .deliveryDay li span {
  font-size: 1.5rem;
}
.deliveryForm .formbutton {
  margin-top: 20px;
  width: 100%;
  text-align: center;
}

.deliveryForm .formbutton button {
  margin-top: 20px;

  width: 280px;
  margin: 40px auto;
}

/* Payment form */

.paymentForm .inputerror {
  top: 63px;
  left: -29px;
  z-index: 2;
}

.paymentForm input {
  padding-left: 50px;
}

.paymentForm i {
  position: absolute;
  top: 29px;
  left: 10px;
  font-size: 3rem;
}

.paymentForm h3 {
  margin-top: 40px;
}

.payment h1 {
  margin-bottom: 0;
}

.payment input[type="checkbox"] {
  width: fit-content;
  display: inline;
  position: relative;
  top: -2px;
  margin-right: 5px;
}

.payment .billingAddress {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
}

.payment .billingAddress {
  display: none;
}
.checkboxAddress label {
  font-size: 2rem;
  color: var(--green);
}

.loader {
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 1;
  width: 120px;
  height: 120px;
  margin: -76px 0 0 -76px;
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

.animate-bottom {
  position: relative;
  -webkit-animation-name: animatebottom;
  -webkit-animation-duration: 1s;
  animation-name: animatebottom;
  animation-duration: 1s;
}

@keyframes animatebottom {
  from {
    bottom: -100px;
    opacity: 0;
  }

  to {
    bottom: 0;
    opacity: 1;
  }
}
