/* Using a string */

.container {
  width: 100%;
  max-width: 1900px;
  margin: 0 auto;
  padding: 0px 20px;
}

.container h2 {
  font-size: 2rem;
}
.wrapper {
  position: relative;
  z-index: 0;
  background-repeat: no-repeat;
  background-position: top center;
  max-width: 1800px;
  margin: auto;
  height: 100%;
}

.wrapper.boxes {
  background-image: url(../../public/images/backgrounds/add.svg);
  /* background-repeat: no-repeat; */
  background-position: top right;
}

.wrapper.prefs {
  background-image: url(../../public/images/backgrounds/meals.svg);
  /* background-repeat: no-repeat;
    background-position: top right -190px; 
    background-size: 55%;*/
}

.wrapper.signup {
  background-image: url(../../public/images/backgrounds/meals.svg);
  /* background-repeat: no-repeat;*/
  background-position: top center;
  /* background-size: 55%; */
}

.wrapper button {
  margin: 10px auto;
}

.wrapper.delivery,
.wrapper.login {
  background-image: url(../../public/images/backgrounds/deliver.svg);
  /* background-repeat: no-repeat;
    background-position: top right ;
    background-size:30em ; */
}

.wrapper.login {
  /* background-size: 55%; */
}

/* .wrapper.signup {
   background-size: contain;
   
} */

.wrapper.checkout {
  background-image: url(../../public/images/backgrounds/cook.svg);
}

.boxesPeople {
  border-bottom: solid 1px var(--green);
  margin: auto auto 40px;
  max-width: 1900px;
  text-align: center;
  font-size: 2rem;
}

.boxesPeople h3 {
  margin: 0;
}

.boxesPeople small {
  color: var(--green);
}

.boxesData {
  text-align: left;
  margin: 80px auto 80px;
  max-width: 800px;
  color: var(--green);
  display: none;
}

.container.mealsContainer .dateRange ul {
  width: auto;
}

.mealsData {
  text-align: left;
  margin: 10px auto 80px;
  max-width: 800px;
  color: var(--green);
  background: var(--beige);
  padding: 21px;
  border-radius: 16px;
  display: none;
}

.mealsData p,
.boxesPrice p {
  font-size: 2rem;
  color: var(--green);
}

.mealsData span {
  font-weight: bold;
}

.boxesPrice p {
  margin: 15px 0;
}

p.totalPrice {
  font-size: 2.5rem;
  margin-top: 15px;
}

.boxesPrice {
  margin-top: 50px;
  color: var(--green);
  font-weight: bold;
  line-height: 1.5em;
  margin: auto auto 40px;
  max-width: 800px;
}

.boxesPrice span {
  text-align: right;
  float: right;
}

.boxesPrice p.warning {
  color: var(--red);
  font-size: 1.6rem;
  line-height: 2rem;
}
.radioGroup input[type="radio"] {
  display: none;
  cursor: pointer;
}

.radioGroup label {
  display: inline-block;
  background-color: var(--green);
  padding: 4px 11px;
  font-family: Arial;
  cursor: pointer;
  width: 70px;
  height: 70px;
  place-content: center;
  font-weight: bold;
  color: var(--nearwhite);
  margin: 18px;
  border-radius: 10px;
}

.radioGroup label:hover {
  border: solid 1px red;
}

.radioGroup input[type="radio"]:checked + label {
  background-color: var(--red);
}

.radioGroup input[type="radio"]:disabled + label {
  background-color: #c3c3c3;
}

.radioGroup input[type="radio"]:checked:disabled + label {
  background-color: #a3a3a3;
}

.nextButton {
  display: block;
  margin: 20px auto;
  transition: all 0.2s ease;
}

.nextButton:hover {
  background-color: var(--green);
  color: var(--nearwhite);
}

.checkboxes-group input[type="checkbox"] {
  display: none;
}

.radioGroup input[type="radio"] {
  display: none;
  cursor: pointer;
}

.checkboxes-group {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px 10px;
  max-width: 1000px;
  margin: auto;
  padding-bottom: 40px;
}

.checkboxes-group div {
  width: fit-content;
}

.checkboxes-group input[type="checkbox"] + label {
  background: #fff;
  height: 100px;
  min-width: 125px;
  display: inline-block;
  text-align: center;
  place-content: end;
  border: solid 1px red;
  margin: 0;
  text-transform: capitalize;
  padding: 20px 20x 5px;
  color: var(--red);

  border: solid 1px #ccc;
  cursor: pointer;
  position: relative;
  background-repeat: no-repeat;
  background-size: 80px;
  background-position: top center;
  border-radius: 16px;
  width: 155px;
  transition: background-color 1000ms linear;
  padding-bottom: 5px;
  font-size: 1.7rem;
}

.checkboxes-group input[type="checkbox"] + label:hover {
  border: solid 1px var(--green);
}

.checkboxes-group input[type="checkbox"]:checked + label {
  background: var(--green);

  color: var(--nearwhite);
  background-repeat: no-repeat;
  background-size: 80px;
  background-position: top center;
}

.checkboxes-group input[type="checkbox"] + label.familyfriendly,
.userDashboard li.preferenceBox.familyfriendly,
.planSetup li.peeps {
  background-image: url(../../public/images/familyfriendly.png);
}

.checkboxes-group input[type="checkbox"] + label.kidfriendly,
.userDashboard li.preferenceBox.kidfriendly {
  background-image: url(../../public/images/kidfriendly.png);
}

.checkboxes-group input[type="checkbox"] + label.lowcarb,
.userDashboard li.preferenceBox.lowcarb {
  background-image: url(../../public/images/lowcarb.png);
}

.checkboxes-group input[type="checkbox"] + label.vegetarian,
.userDashboard li.preferenceBox.vegetarian {
  background-image: url(../../public/images/vegetarian.png);
}

.checkboxes-group input[type="checkbox"] + label.pescatarian,
.userDashboard li.preferenceBox.pescatarian {
  background-image: url(../../public/images/pescatarian.png);
}

.checkboxes-group input[type="checkbox"] + label.caloriesmart,
.userDashboard li.preferenceBox.caloriesmart {
  background-image: url(../../public/images/lowcalorie.png);
}

.checkboxes-group input[type="checkbox"] + label.classics,
.userDashboard li.preferenceBox.classics {
  background-image: url(../../public/images/classics.png);
}

.checkboxes-group input[type="checkbox"] + label.guiltfree,
.userDashboard li.preferenceBox.guiltfree,
.planSetup li.guiltf {
  background-image: url(../../public/images/guiltfree.png);
}

.checkboxes-group input[type="checkbox"] + label.chicken,
.userDashboard li.preferenceBox.chicken {
  background-image: url(../../public/images/chicken.png);
}

.checkboxes-group input[type="checkbox"] + label.meat,
.userDashboard li.preferenceBox.meat {
  background-image: url(../../public/images/meat.png);
}

.checkboxes-group input[type="checkbox"] + label.gourmet,
.userDashboard li.preferenceBox.gourmet {
  background-image: url(../../public/images/gourmet.png);
}

.checkboxes-group input[type="checkbox"] + label.none,
.userDashboard li.preferenceBox.none {
  background-image: url(../../public/images/none.png);
}

.checkboxes-group input[type="checkbox"] + label.breakfast,
.userDashboard li.preferenceBox.breakfast {
  background-image: url(../../public/images/breakfast.png);
}

.paymentForm {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  /* width: 80%;
    margin: auto; */
}

/* .paymentForm input{
    margin-right: 40px;
    min-width: 380px;
  } */

.paymentForm button {
  margin: 20px auto;
}

.paySuccess {
  flex-direction: column;
  place-content: center;
}

.summaryMeals {
  display: flex;
  flex-wrap: wrap;
  gap: 40px;
}

.checkoutMeals {
  width: 100%;
}

.checkoutMeals .mealcard {
  height: fit-content;
}

.summaryDiv {
  padding-bottom: 20px;
  border-bottom: solid 2px var(--red);
  margin-bottom: 20px;
  flex-wrap: wrap;
  background: #fff;
  border-radius: 20px;
  border: solid 1px var(--green);
  padding: 10px;
}

.summaryDiv h2 {
  width: 100%;
  margin: 0px;
}

.summaryDiv .detailsdiv {
  width: auto;
  margin-right: 0px;
}

.summaryDiv .boxesData {
  display: block;
  width: 100%;
  margin: 0;
  max-width: 100%;
}

.summaryDiv .boxesPrice {
  max-width: 100%;
  margin: 0;
  width: 100%;
}

.summary button {
  margin: auto auto 20px;
  width: 180px;
  display: block;
}

.summary button:hover {
  background-color: var(--green);
}

.errorContainer p.error {
  display: flex;
  margin: auto auto 60px;
}

.selectedTextDiv {
  display: flex;
  flex-direction: column;
  place-content: center;
  width: 100%;
  max-width: 300px;
}

.addmeals {
  background-color: var(--green);
  border-radius: 20px;
  color: var(--nearwhite);
  height: 30px;
  width: 150px;
  place-content: center;
  text-align: center;
  cursor: pointer;
  place-self: center;
}

.signupNav {
  display: flex;
  flex-wrap: wrap;
  gap: 2px;
  justify-content: center;
  margin: 20px auto;
  font-size: 1.5rem;
  color: var(--green);
  font-weight: bold;
  padding: 10px 20px;
}

.signupNav li::after {
  content: "~";
  margin: 0 5px;
}

.signupNav li:last-child::after {
  content: "";
}
.signupNav li span.disabled {
  color: grey;
  opacity: 0.5;
}

.phoneInput {
  display: flex;
  align-items: center;
  position: relative;
}

.phoneInput input {
  padding-left: 80px;
}

.phoneInput span {
  background-color: var(--green);
  padding: 15px;
  font-size: 2rem;
  position: absolute;
  top: 20px;
  border-radius: 10px 0 0 10px;
  color: var(--nearwhite);
}

.detailsdiv p {
  color: var(--green);
  font-size: 1.8rem;
  margin: 0 auto 8px;
}

@media screen and (min-width: 490px) {
  .summaryDiv .detailsdiv {
    width: auto;
    margin-right: 100px;
  }
}

@media screen and (min-width: 800px) {
  .radioGroup label {
    display: inline-block;
    background-color: var(--green);
    padding: 4px 11px;
    font-family: Arial;
    cursor: pointer;
    width: 80px;
    height: 80px;
    place-content: center;
    font-weight: bold;
    color: var(--nearwhite);
    margin: 20px;
    border-radius: 10px;
  }
}

@media screen and (min-width: 1024px) {
  .selectedTextDiv {
    display: block;

    width: auto;
  }

  .addmeals {
    background-color: var(--green);
    border-radius: 20px;
    color: var(--nearwhite);
    height: 30px;
    width: 150px;
    place-content: center;
    text-align: center;
    cursor: pointer;
  }
}
