@font-face {
  font-family: uncutSansBold;
  src: url(../public/fonts/Uncut-Sans-Semibold.otf);
}

@font-face {
  font-family: uncutSans;
  src: url(../public/fonts/Uncut-Sans-Book.otf);
}

@font-face {
  font-family: youngserif;
  src: url(../public/fonts/YoungSerif-Regular.ttf);
}

:root {
  --beige: #e2cfb0;
  --red: #ce5127;
  --green: #5d693b;
  --offwhite: #f3f0ec;
  --nearwhite: #fdfaf7;
  --black: #000000;
  --lightGrey: #f0f0f0;
  font-size: 62.5%;
  --lightred: #DD704B;
  --lightgreen: #92a06c
}



*,
*::after,
*::before {
  box-sizing: border-box;
}



body {
  color: black;
  margin: 0;
  padding: 0;
  background-color: var(--nearwhite);
  font-family: uncutSans;
  box-sizing: border-box;
  min-height: 100%;
  height: 100%;
  font-size: calc(15px + 0.390625vw);
}

button a, button a:visited {
  color: var(--nearwhite);
  text-decoration: none;
}

button a:hover,
button a:active {
  color: var(--green);
    text-decoration: none;
}


 a,
 a:visited {
   color: var(--green);
   text-decoration: none;
  
 }

 a:hover,
 a:active {
   color: var(--red);
 }
 
 i{
  color: var(--green);
 }

button{
  color: var(--nearwhite);
  background: var(--red);
  padding: 5px 10px;
  border-radius: 8px;
  border: var(--offwhite);
  font-size: 1.8rem;
  cursor: pointer;
  min-width: 160px;
  max-width: 220px;
  height: 45px;
  transition: all .35s ease-out;
  margin-bottom: 10px;
}


 button:hover{
  background: var(--green);
  color: var(--nearwhite)
}

button + p + .counter{
  min-width: none;
}

h1,
h2,
h3,
h4 {
  font-family: youngserif;
  font-weight: normal;
  color: var(--green);
  /* margin: 0; */
  padding: 0;
  font-weight: normal;
}
h1{
  display: flex;
  flex-direction: row;
}

.adminDash h1{
  display: block;
}

 h1:before {
   content: "";
   flex: 0 1 10px;
   border-bottom: 2px solid var(--green);
   margin: auto 0px;
   width: 50px;
 }

  h1:after {
   content: "";
   flex: 0 1 100px;
   border-bottom: 2px solid var(--green);
   margin: auto 0px;
 }

h1{
  font-size: 3rem;
  margin-bottom: 20px;
}

h2{
  font-size: 2.25rem;
  
}

h3,
h4{
  font-size: 2rem;

 
}

ul,li{
  margin: 0;
  padding: 0;
}

li{
  display: inline-block;
}

p{
  font-size: 2rem;
  margin: 0 0 20px 0;
}
 .appContainer {
  min-height: 100vh;
  display: grid;
  grid-template-rows: 75px 1fr 220px;
  /* font-size: 2rem; */
 }

img {
  width: 100%;
  font-size: 1.5rem;
}


.flex {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}

/* .content{
  margin-bottom: 100px;
} */


/* modal copntent */
.recipieCard {
  width: 33.33%;
  position: fixed;
  top: 10vh;
  z-index: 999;
  background: var(--beige);
  max-height: calc(100vh - 210px);
  overflow-y: auto;
}

.overlay {
  background: rgba(0, 0, 0, .5);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

dialog {
  width: fit-content;
  max-width: 1800px;
  transition: transform 1s ease;
  transform: translateY(120vh);
  border: none;
  border-radius: 25px;
  box-shadow: 5px 13px 21px rgba(0, 0, 0, 0.5);
  outline: 0;
  margin: auto;
  position: fixed;
  background: transparent;
}

dialog.mealsChange {
  max-width: 90vw;
}

dialog .dateRange {}

dialog .dateRange ul li {
  text-align: center;
  padding: 5px;
}


dialog[open] {
  animation: modalTransitionIn 1s ease forwards;
}

dialog[open].mealsChange {
  animation: fsmodalTransitionIn 1s ease forwards;
}

dialog.closing {
  animation: modalTransitionOut 1s ease forwards;
}


.mealsChange .modalBody :not(.dateRange) ul li {
  border: none;
}

.modalBody li.active {
  background-color: var(--red);
  color: var(--nearwhite)
}

#dialog-box h2 {
  margin-top: 0;
}

.pagenotfound {
  margin: auto;
  place-content: center;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  justify-items: center;
  align-items: center;
}
.pagenotfound h1 {
  font-size: 25rem;
  margin-bottom: 0;
}
.pagenotfound h2 {
  font-size: 3rem;
  margin-bottom: 20px;
} 

button.saveMeals{
  text-align: center;
    place-content: center;
    margin: 20px 10px;
    width: 160px;
    display: flex;
    padding-top: 11px;
}

/* HTML: <div class="loader"></div> */
.loader {
  width: 50px;
  aspect-ratio: 1;
  border-radius: 50%;
  border: 8px solid #514b82;
  animation:
    l20-1 0.8s infinite linear alternate,
    l20-2 1.6s infinite linear;
}

/* Loading.css */
.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  height: 100vh;
}

.spinner {
  border: 16px solid #f3f3f3;
  border-top: 16px solid var(--green);
  border-radius: 50%;
  width: 80px;
  height: 80px;
  animation: spin 2s linear infinite;
  position: relative;
    top: -100px;
}

.loading-container img{
  width: 20vw}

  .forgotPassword{
    font-size: 1.75rem;
  }

  .orderhistory-meal{
    display: flex;
    flex-direction: row;
    gap: 10px;
  }

    .orderhistory-meal:nth-child(even) {
      background: white;
    }
  .orderhistory-meal:nth-child(odd){
      background: var(--lightGrey);
    } 

  .orderhistory-mealSet{
    border: 1px solid var(--green);
      border-radius: 10px;
      margin: 10px 0;
      padding: 0 10px;
  }

  .orderhistory-mealSet h3{
   color: var(--nearwhite);
   padding: 10px;
    background: var(--green);
  }

    .orderhistory-meal .mealTitle{
      justify-self: center;
      align-self: center;
      color: var(--green);
      font-family: "unct-sans";
    }

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes l20-1 {
  0% {
    clip-path: polygon(50% 50%, 0 0, 50% 0%, 50% 0%, 50% 0%, 50% 0%, 50% 0%)
  }

  12.5% {
    clip-path: polygon(50% 50%, 0 0, 50% 0%, 100% 0%, 100% 0%, 100% 0%, 100% 0%)
  }

  25% {
    clip-path: polygon(50% 50%, 0 0, 50% 0%, 100% 0%, 100% 100%, 100% 100%, 100% 100%)
  }

  50% {
    clip-path: polygon(50% 50%, 0 0, 50% 0%, 100% 0%, 100% 100%, 50% 100%, 0% 100%)
  }

  62.5% {
    clip-path: polygon(50% 50%, 100% 0, 100% 0%, 100% 0%, 100% 100%, 50% 100%, 0% 100%)
  }

  75% {
    clip-path: polygon(50% 50%, 100% 100%, 100% 100%, 100% 100%, 100% 100%, 50% 100%, 0% 100%)
  }

  100% {
    clip-path: polygon(50% 50%, 50% 100%, 50% 100%, 50% 100%, 50% 100%, 50% 100%, 0% 100%)
  }
}

@keyframes l20-2 {
  0% {
    transform: scaleY(1) rotate(0deg)
  }

  49.99% {
    transform: scaleY(1) rotate(135deg)
  }

  50% {
    transform: scaleY(-1) rotate(0deg)
  }

  100% {
    transform: scaleY(-1) rotate(-135deg)
  }
}


@keyframes modalTransitionIn {
  0% {
    transform: translateY(120vh);
  }

  100% {
    transform: translateY(0vh);
  }

}


@keyframes fsmodalTransitionIn {
  0% {
    transform: translateY(120vh);
  }

  100% {
    transform: translateY(5vh);
  }

}

@keyframes modalTransitionOut {
  0% {
    transform: translateY(30vh);
  }

  100% {
    transform: translateY(120vh);
  }

}


@keyframes heroSwipe {
  0% {

    opacity: 0;
  }

  100% {
    opacity: 1;
  }
  }