:root {
  --animation-timing: 400ms ease-in-out;
  --hamburger-height: calc(var(--bar-height) * 3 + var(--hamburger-gap) * 2);
  --hamburger-margin: 18px;
  --foreground: var(--beige);
  --background: var(--green);
  --bar-width: 60px;
  --bar-height: 8px;
  --hamburger-gap: 6px;
}

nav {
  padding: 0;
  display: grid;
  grid-template-columns: 60px 200px auto;
  grid-template-rows: 1fr;
  align-items: center;
  white-space: nowrap;
  /* Safari */
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 999;
  background-color: var(--offwhite);
  height: 75px;
}

nav .sidebar a,
nav .sidebar a:visited,
nav .sidebar a:hover {
  color: var(--green);
}

nav a:hover {
  color: var(--red);
}

button {
  cursor: pointer;
  transition: all 0.25s ease-in-out;
}

button:hover {
  background-color: var(--green);
  color: var(--offwhite);
}

.sidebar object {
  height: 30px;
  width: 24px;
  margin-right: 6px;
  vertical-align: middle;
}

.hamburgerMenu {
  display: flex;
  flex-direction: column;
  gap: var(--hamburger-gap);
  width: max-content;
  --x-width: calc(var(--hamburger-height) * 1.41421356237);
  width: max-content;
  position: absolute;
  top: 18px;
  left: 1px;
  z-index: 2;
  scale: 0.5;
  cursor: pointer;
}

.hamburgerMenu::before,
.hamburgerMenu::after,
.hamburgerMenu input {
  content: "";
  display: block;
  background-color: var(--green);
  height: var(--bar-height);
  width: var(--bar-width);
  border-radius: 9999px;
  transform-origin: left center;
  transition: opacity var(--animation-timing), width var(--animation-timing),
    rotate var(--animation-timing), background-color var(--animation-timing),
    width var(--animation-timing);
}

.hamburgerMenu input {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  padding: 0;
  margin: 0;
  outline: none;
  pointer-events: none;
}

.hamburgerMenu input:checked {
  width: 0;
  opacity: 0;
}

.hamburgerMenu:has(input:checked)::before {
  rotate: 45deg;
  width: var(--x-width);
  translate: 0 calc(var(--bar-height) / -2);
  background-color: var(--green);
}

.hamburgerMenu:has(input:checked)::after {
  rotate: -45deg;
  width: var(--x-width);
  translate: 0 calc(var(--bar-height) / 2);
  background-color: var(--green);
}

.hamburgerSection.inslet {
  grid-column: 3;
  grid-row: 1;
  justify-self: end;
}

.sidebar {
  display: block;
  translate: 0 -100%;
  transition: translate var(--animation-timing);
  padding: 0.5rem 1rem;
  padding-top: calc(--hamburger-height + var(--hamburger-margin)+1rem);
  width: 300px;
  height: fit-content;
  background: #fff;
  font-family: youngserif;
  position: absolute;
  top: 0;
  border: solid 1px var(--green);
  border-radius: 9px;
  padding-left: 24px;
}

.aside {
  transition: translate var(--animation-timing);
  translate: 0 -100%;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  /* background: rgba(0, 0, 0, 0.5); */
}

.topbar {
  display: none;
}

.logoSection {
  grid-column: 2;
}

.hamburgerMenu:has(input:checked) + aside .sidebar,
.hamburgerMenu:has(input:checked) + .aside {
  translate: 0;
}

.hamburgerMenu:has(input:checked) + aside .sidebar {
  top: 65px;
}

.hamburgerMenu:has(input:focus-visible)::before,
.hamburgerMenu:has(input:focus-visible)::after,
.hamburgerMenu:has(input:focus-visible) {
  border: 1 solid var(--red);
  box-shadow: 0 0 0 1px var(--offwhite);
}

nav button {
  grid-column: 3;
  grid-row: 1;
  max-width: fit-content;
  justify-self: end;
  margin-right: 40px;
}

nav i.bi-person-circle {
  font-size: 3.5rem;
  color: var(--green);
  margin-right: 40px;
  cursor: pointer;
  z-index: 2;
  position: relative;
}

.topbut {
  display: none;
}

.sidebar li {
  margin-bottom: 20px;
  display: block;
  font-size: 1.9rem;
  width: 100%;
}

img.logo {
  width: 145px;
  margin-top: 10px;
}

nav ul {
  margin: 15px 20px;
  padding-top: 10px;
  display: flex;
  flex-wrap: wrap;
}

nav .sidebar ul {
  margin: 10px 0px;
  padding-top: 10px;
}

nav ul li {
  margin: 0 40px 0 0;
  display: block;
  cursor: pointer;
}

nav ul li i {
  margin-right: 10px;
}

.navPanel {
  background: #fff;
  border: solid 1px var(--green);
  position: absolute;
  top: 0;
  border-radius: 9px;
  width: 300px;
  right: -300px;
  transition: all 0.5s ease-in;
  display: none;
}
nav .navPanel ul li {
  width: 100%;
  margin-bottom: 20px;
}

.navPanel.open {
  right: 0px;
  display: block;
}

.navButton {
  position: relative;

  right: 10px;
  justify-self: end;
}

@keyframes slider {
  0% {
    translate: 0 0;
  }

  100% {
    translate: 100% 0;
  }
}

@media screen and (min-width: 550px) {
}

@media screen and (min-width: 800px) {
}

@media screen and (min-width: 1024px) {
  nav {
    grid-template-columns: 100px auto 210px;
  }

  .hamburgerMenu input {
    display: none;
  }
  nav .navButton {
    grid-column: 3;
    grid-row: 1;
    max-width: fit-content;
    justify-self: end;
    margin-right: 40px;
  }

  .topbut {
    display: block;
    margin-top: 10px;
  }

  nav ul {
    align-self: center;
    display: flex;
    flex-direction: row;
    padding-top: 5px;
    order: 2;
    flex-basis: fit-content;
    grid-column: 2;
    grid-row: 1;
    justify-self: end;
    font-size: 1.6rem;
    margin: 15px 10px;
  }

  .topbar {
    display: block;

    padding-top: 0;
    max-width: 100rem;
    min-height: 0;
    background: transparent;
    font-family: youngserif;
    color: var(--black);
    position: relative;
    justify-self: end;
  }

  .topbar a {
    color: var(--green);
  }

  .hamburgerMenu:has(input:checked) + aside .sidebar,
  .hamburgerMenu:has(input:checked) + .aside {
    translate: -100% -100%;
  }

  .hamburgerSection.inslet {
    grid-column: 3;
    justify-self: end;
  }

  .logoSection {
    grid-column: 1;
    grid-row: 1;
    padding: 15px 40px;
    width: 240px;
    align-self: start;
    height: 75px;
  }

  /* .logoSection.lgin{
background: var(--lightred);
     } */

  .navButton {
    grid-column: 3;
    justify-self: end;
  }

  .navButton button {
    font-size: 1.5rem;
  }
}

@media screen and (min-width: 1024px) {
  .hamburgerMenu {
    display: none;
  }

  .sidebar {
    display: none;
  }
}
