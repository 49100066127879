.modalWarning {
  color: var(--red);
  font-size: 2.5rem;
  padding: 20px;
}

.modalTop {
  height: 50px;
  padding: 30px;
}

.modalTop {
  display: grid;
  border-bottom: solid 1px gray;
  align-items: center;
  align-content: center;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 55px;
  background: var(--beige);
  border-radius: 20px 20px 0 0;
  padding: 10px;
  font-weight: bold;
  font-size: 1.6rem;
}

dialog::backdrop {
  background-color: var(--green);
  opacity: 0.4;
}

dialog.recipe {
  width: 90vw;
  height: 80vh;
  padding: 0;
  max-width: 800px;
}

.recipe .modalTop {
  display: flex;
  border-bottom: solid 1px gray;
  justify-content: end;
  background: #fff;
  border-radius: 20px 20px 0 0;
  padding: 10px;
  font-weight: bold;
  font-size: 1.6rem;
  margin-right: auto;
  position: sticky;
  top: 0px;
}

.recipe .topSection {
  position: sticky;
  top: 50px;
  background: #fff;
  padding: 20px;
}

.recipe .topSection h1,
.recipe .topSection h4 {
  /* font-size: 3.5rem; */
  margin: 0;
}

.recipe .topSection h4 {
  color: var(--red);
}

.recipe .topSection p {
  margin-top: 20px;
  color: darkgray;
  display: none;
}

.recipe .modalBody {
  padding: 0;
}

.recipe .modalBody img {
  width: 100%;
}

.recipeContent {
  padding: 20px 20px 60px;
  font-size: 1.5rem;
  color: #484848;
}

.recipeContent h4 {
  margin-top: 20px;
}

.recipeContent .warning {
  color: var(--red);
  margin: 12px 0 20px;
  font-size: 1.9rem;
}

.recipeContent .nutrition {
  display: flex;
  flex-direction: column;
}

.recipeContent .nutrition p {
  font-size: 1.75rem;
}

.recipeContent .nutrition span {
  float: right;
}

.recipeContent .ingredients {
  margin-top: 20px;
}
.recipeContent .ingredients p {
  padding: 5px;
  border-radius: 10px;
}

.recipeContent .ingredients p:nth-child(odd) {
  background-color: var(--lightred);
  color: var(--offwhite);
}

.instructions {
  padding-top: 30px;
  font-size: 2rem;
}

.insTitle {
  color: var(--red);
  font-weight: bold;
  margin: 10px 0 0;
}

.modalTop button {
  justify-self: end;
  align-self: center;
  background: transparent;
  margin-right: 0;
  color: var(--green);
  border: solid 1px var(--green);
  border-radius: 20px;
  width: 34px;
  height: 34px;
  min-width: 0;
  margin-right: 0;
}

.modalBody {
  border-radius: 0 0 25px 25px;
  background: var(--nearwhite);
  padding: 10px 5px;
  font-size: 1.4rem;
}

.modalBody p {
  margin: 0;
}

.modalBody ul {
  padding-bottom: 20px;
}

.modalBody li {
  background-color: transparent;

  padding: 5px;
  margin-right: 0px;
  cursor: pointer;
}

.modalBody {
  font-size: 2rem;
  display: flex;
  flex-direction: column;
  padding: 30px;
}

.modal-container {
  opacity: 0;
  transition: all 0.2s ease-in-out;
}

.modal-container.show {
  opacity: 1;
}

@keyframes modalfadein {
  form {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

#checkoutModal .modalTop {
  width: 100%;
  /* position: fixed; */
}

.modalBody img,
.modalBody h3 {
  width: 100%;
  margin: auto;
}

.modalBody button {
  width: 200px;
  margin: auto;
}

/* modal.css */

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  /* Ensure it covers other elements */
}

.modal-container {
  background: var(--nearwhite);
  padding: 0;
  border-radius: 4px;
  max-width: 800px;
  height: fit-content;
  max-height: 90vh;
  overflow: auto;
  width: 90vw;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 10000;
  position: relative;
}

.modal-close {
  position: absolute;
  top: 0px;
  right: 2px;
  background: var(--green);
  font-size: 1em;
  cursor: pointer;
  color: var(--nearwhite);
  text-align: center;
  width: fit-content;
  padding: 0px 18px;
  border: none;
  border-radius: 0 0 0 30px;
  min-width: 33px;
  height: 33px;
}

.modal-body {
  text-align: left;
  font-size: 2rem;
  display: grid;
  grid-template-columns: 100%;
}

.modal-body .imageContainer {
  max-height: 500px;
  min-height: 200px;
  overflow: hidden;
  grid-row: 1;
}

.modal-body .blurb {
  background: var(--green);
  color: var(--nearwhite);
  padding: 20px;
  font-family: uncut-Sans;
  position: relative;
  top: 0;
  grid-row: 2;
}

.modal-body .blurb h1,
.modal-body .blurb h2,
.modal-body .blurb h3,
.modal-body .blurb h4 {
  color: var(--nearwhite);
  margin: 0;
}

.modal-body p {
  font-size: 2rem;
  margin-top: 10px;
}

.modal-body .mealTypes {
  position: relative;

  background-color: var(--green);
  font-size: 1.75rem;
  padding-left: 16px;
  color: var(--green);
}

.modal-body .mealTypes li {
  background-color: var(--nearwhite);
  border-radius: 8px;
  padding: 6px;
  text-transform: capitalize;
  margin-right: 10px;
  margin-bottom: 10px;
  width: 45%;
  border: solid 1px var(--green);
  font-size: 1.5rem;
  border: solid 1px var(--green);
}

.modal-section {
  display: block;
  margin: 40px auto;
}

/* .modal-section h4 {
    margin-bottom: 10px;
} */

.modal-section h4 {
  display: flex;
  flex-direction: row;
}

.modal-section h4:before {
  content: "";
  flex: 0 1 10px;
  border-bottom: 2px solid var(--green);
  margin: auto 0px;
  width: 50px;
}
.modal-section h4:after {
  content: "";
  flex: 0 1 100px;
  border-bottom: 2px solid var(--green);
  margin: auto 0px;
}

/* .modal-section ul li::after {
    content: " | ";
}

.modal-section ul li:last-child::after {
    content: "";
} */

/* button:hover {
     background: var(--nearwhite);
     color: var(--green);
 } */

.modal-section ul {
  width: 100%;
  color: var(--nearwhite);
  /* background: var(--green); */
  width: 90%;
  padding: 10px;
  margin: auto;
  columns: 1;
}

.modal-section ul li {
  width: 90%;
  display: inline-block;
  margin-bottom: 10px;
  background: var(--lightgreen);
  padding: 10px;
  margin-bottom: 10px;
  font-size: 1.75rem;
}

.modal-body .modal-content {
  columns: 1;
  padding-left: 0px;
}

.modal-body .modal-content h4 {
  margin: 10px 0 10px 20px;
}

.modal-signup {
  background-color: var(--green);
  color: var(--nearwhite);
  font-size: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  columns: 2;
}
.modal-signup p {
  font-size: 2.5rem;
  font-family: youngserif;
  line-height: 3.5rem;
}

.modal-signup img {
  width: 185%;
  align-self: center;
  position: relative;
  left: -50%;
  filter: brightness(1000%);
}

.modal-signup div {
  width: 45%;
}

.modal-signup button {
  margin: auto;
  position: relative;
  left: 0%;
}

@media screen and (min-width: 600px) {
  .modal-signup img {
    width: 145%;
    align-self: center;
    position: relative;
    left: -25%;
    filter: brightness(1000%);
  }

  .modal-signup p {
    font-size: 3rem;
    font-family: youngserif;
    line-height: 3.5rem;
  }

  .modal-signup button {
    margin: auto;
    position: relative;
    left: 0%;
  }
}

@media screen and (min-width: 800px) {
  .modal-signup img {
    width: 125%;
    align-self: center;
    position: relative;
    left: 0%;
    filter: brightness(1000%);
  }

  .modal-signup p {
    font-size: 3rem;
    font-family: youngserif;
    line-height: 3.5rem;
  }

  .modal-signup button {
    margin: auto;
    position: relative;
    left: 0%;
  }
}

@media screen and (min-width: 1024px) {
  .modal-body .mealTypes {
    position: relative;
    background-color: transparent;
    font-size: 1.75rem;
    padding-left: 16px;
    color: var(--green);
    grid-row: 2;
    z-index: 999;
    margin-top: -50px;
  }
  .modal-body .blurb {
    background: var(--green);
    color: var(--nearwhite);
    padding: 20px;
    font-family: uncut-Sans;
    position: relative;
    top: 0;
    grid-row: 3;
  }

  .modal-body .mealTypes li {
    background-color: var(--nearwhite);
    border-radius: 8px;
    padding: 6px;
    text-transform: capitalize;
    margin-right: 10px;
    margin-bottom: 10px;
    width: 20%;
    max-width: 200px;
    border: solid 1px var(--green);
    font-size: 1.5rem;
    border: solid 1px var(--green);
  }
  .modal-section ul li {
    width: 90%;
    margin-right: 20px;
  }
}
