.adminDash {
  width: 100%;
  display: grid;
  grid-template-columns: 250px 1fr;
  grid-gap: 20px;
  padding: 60px;
  padding: 60px 60px 0;

  grid-template-rows: 150px 1fr;
}

.adminDash h1 {
  grid-column: 1/-1;
}

.themeal {
  display: grid;
  grid-template-columns: 100px 1fr 180px 180px;
  grid-gap: 10px;
  font-size: 2rem;
  margin-bottom: 20px;
  border-bottom: solid 1px var(--green);
  padding: 5px;
  justify-content: start;
  align-content: center;
  align-items: center;
  padding-bottom: 10px;
}
.themeal button {
  margin-right: 150px;
}

.afDelete {
  display: none;
}

.weekRange {
  width: 50vw;
  overflow: auto;
  margin: auto;
  grid-column: 1/-1;
  padding-bottom: 20px;
}

.twocolGrid {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: 100px 1fr;
  padding-bottom: 40px;
}

.twocolGrid .dateRange {
  display: flex;
  white-space: nowrap;
}

.twocolGrid .menuItems .currentP:nth-child(even),
.twocolGrid .themeal:nth-child(even) {
  background-color: var(--lightGrey);
}

.twocolGrid .themeals {
  grid-column: 1;
  grid-row: 2;
  justify-self: end;
  border-right: solid 4px var(--green);
  padding-right: 20px;
  margin-top: 80px;
}

.twocolGrid .themeal {
  display: block;
  /* background-color: var(--green);
    color: var(--nearwhite); */
  cursor: pointer;
  margin-bottom: 5px;
  border: none;
}

.twocolGrid .themeal:hover {
  background-color: var(--red);
  color: var(--nearwhite);
  position: relative;
}

.twocolGrid .themeal:hover::after {
  content: "⇒";
  color: var(--nearwhite);
  font-size: 2rem;
  display: inline;
  text-align: right;
  display: inline-block;
  width: 100%;
  right: 20px;
  top: 0;
  position: absolute;
}

.twocolGrid .menuItems {
  padding-left: 40px;
  font-size: 2rem;
  width: 60%;
  margin-top: 80px;
}

.twocolGrid .menuItems div,
.twocolGrid .menuItems .currentP {
  position: relative;
  cursor: pointer;
  width: fit-content;
  min-width: 550px;
}

.twocolGrid .menuItems .current:hover {
  color: var(--nearwhite);
  background-color: var(--green);
  position: relative;
  padding-left: 40px;
}

.twocolGrid .menuItems .current:hover::after {
  content: "⇐";
  color: var(--nearwhite);
  font-size: 2rem;
  display: inline;
  text-align: left;
  display: inline-block;
  width: 100%;
  left: 20px;
  top: 0;
  position: absolute;
}

.twocolGrid .menuItems div {
  padding: 5px;
}

.twocolGrid h3 {
  margin-bottom: 40px;
}

.twocolGrid button {
  grid-column: 1/-1;
  width: 200px;
  margin: 40px auto;
}

.wkdeliveryDay {
  margin-bottom: 80px;
  display: flex;
  flex-wrap: wrap;
  /* gap: 50px */
}

.wkdeliveryDayS {
  margin-bottom: 80px;
  display: flex;
  flex-wrap: wrap;
  gap: 50px;
  flex-direction: row;
  border-bottom: solid 1px var(--green);
  width: 100%;
}

.wkdeliveryDayS .summarybtn {
  margin-left: auto;
}

.wkdeliveryDayS button {
  margin-left: 40px;
}

.wkdeliveryDay div {
  width: 100%;
}

.orderTable {
  width: 100%;
  text-align: center;
  font-size: 2rem;
}

.orderSummary h2 {
  border: none;
  width: auto;
}

.orderSummary div {
  width: 100%;
}

table.summary {
  margin-bottom: 120px;
}

.summary tr:nth-child(even) {
  background-color: var(--lightGrey);
}

.summaryprep li {
  display: block;
}

.usersTable {
  display: table;
}

.usersTableHeader {
  display: table-row;
  border-bottom: solid 1px var(--green);
}

.usersTableHeader div {
  display: table-cell;
  font-size: 2rem;
  color: var(--green);
  font-weight: bold;
  border-bottom: solid 1px var(--green);
}

.usersTable div:not(.usersTableHeader, .userRow) {
  display: table-cell;
  font-size: 1.85rem;
  color: var(--green);
  border-bottom: solid 1px var(--green);
  padding: 20px;
  vertical-align: middle;
}
.usersTable li {
  display: block;
}

.usersTable .userRow {
  display: table-row;
  border-bottom: solid 1px var(--green);
}

.wkdeliveryDay h2 {
  width: 100%;
  border-bottom: solid 1px var(--green);
}

.wkdeliveryDay .address {
  margin-top: 40px;
}

.wkdeliveryDay li {
  display: block;
}

.classicP.classic,
.themeal.classic {
  background: var(--red);
  color: var(--nearwhite);
}
.wkdeliveryDay {
  font-size: 1.85rem;
}
.wkdeliveryDay .user {
  border: solid 1px green;
  padding: 18px;
  border-radius: 20px;
}

.wkdeliveryDay span {
  font-size: 1.85rem;
}
.summary,
.summaryprep {
  width: 100%;
}

.summary tbody td,
.summaryprep tbody td {
  font-size: 2rem;
}

.summaryprep tbody td {
  text-align: center;
}

.summary tbody td:not(:first-child) {
  text-align: center;
}
.tablediv {
  margin: 10px;
  padding: 10px;
  text-align: center;
  font-size: 2rem;
  color: var(--green);
}

.tablediv li {
  display: block;
  font-size: 2rem;
  text-align: left;
}

.ing {
  width: 50%;
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}

.ingredient {
  border: solid 1px gray;
  padding: 10px;
}

.ingredientsList li {
  display: block;
}

.driversDownload button {
  margin-right: 20px;
}

.orderTable tr:nth-child(even) {
  background-color: var(--lightGrey);
}

.orderTable {
  margin-bottom: 100px;
  max-width: fit-content;
}

.orderTable td {
  padding: 20px 0;
}

.prepTable {
  width: 100%;
  text-align: center;
  font-size: 2rem;
}

.prepTable tr:nth-child(even) {
  background-color: var(--lightGrey);
}

.prepTable {
  margin-bottom: 100px;
}

.prepTable th {
  width: 33%;
}

.prepTable td {
  padding: 20px 0;
}

.ingsTable {
  width: 100%;
}

.heroSection {
  display: grid;
  grid-template-columns: 800px 1fr;

  gap: 20px;
}

.heroSection .mainImg {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.image-gallery img {
  width: 200px;
}

.featuredmealContainer {
  display: flex;
  flex-wrap: wrap;
}

.featuredmeal {
  display: flex;
  grid-template-columns: 40px 80px 180px;
  gap: 40px;
  font-size: 2rem;
  margin-bottom: 20px;
  width: 20vw;
  padding: 5px;
  padding-bottom: 5px;
  justify-content: start;
  align-content: center;
  align-items: center;
  padding-bottom: 10px;
}

.featuredmeal.active {
  background-color: var(--lightGrey);
}

.featuredmeal img {
  width: 80px;
  height: 80px;
  object-fit: cover;
}

.featuredmeal input[type="checkbox"] {
  width: 16px;
  height: 16px;
  margin-right: 10px;
}

.upload-section {
  margin-top: 10px;
}

.upload-button {
  display: inline-block;
  padding: 8px 16px;
  background-color: #007bff;
  color: white;
  border-radius: 4px;
  cursor: pointer;
}

.upload-button:hover {
  background-color: #0056b3;
}

.orderTable tr th {
  text-wrap: nowrap;
}

.orderTable td {
  padding: 30px;
  vertical-align: top;
}

.orderTable .address {
  width: 10%;
}

.orderTable td:last-child {
  width: 20%;
}

.meal-selection {
  text-align: left;
  border-bottom: solid 1px #ccc;
  margin-bottom: 10px;
  width: 343px;
}

.orderSummary table {
  width: 100%;
  text-align: center;
}

.deliverySummary table {
  width: 100%;
  text-align: center;
  font-size: 1.8rem;
}

.deliverySummary table tr:nth-child(even) {
  background-color: var(--lightGrey);
}

.deliverySummary table tr td {
  padding: 20px;
}

.deliverySummary table td.custaddress {
  max-width: 300px;
}

.deliverySummary button {
  height: fit-content;
  margin: 10px;
}
