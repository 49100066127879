.accinfo h1{
    margin-bottom:60px;
}


.uneditable, .editable {
    display: grid;
    grid-template-columns: 1fr ;
    gap: 5px 40px;
    margin: 10px;
    font-size: 2rem;
}

.uneditable.hide, .editable.hide {
    display: none;
}

.infoContainer button, .deliveryContainer button, .paymentContainer button {
    justify-self: end;
    align-self: end;
}



.infoContainer label, .deliveryContainer label, .paymentContainer label {
    font-size: 2rem;
    
}

.actions {
    justify-self: end;
}


.actions button {
    margin-left: 10px;
}

.uneditable li{
    display: block;
    color: var(--green);
    margin-bottom: 10px;
}

.uneditable li span{
    color: var(--green);
    font-weight: bold;
    margin-left: 10px;
}

.uneditable span {
    color: var(--green);
    font-weight: bold;
}