.userDashboard h1,
.userDashboard h2 {
  width: 100%;
  margin: 10px 0 0 10px;
  color: var(--green);
}

.userDashboard h1,
.userContainer h1 {
  font-size: 3rem;
  padding-left: 10px;
  margin-bottom: 20px;
}

.userDashboard h2,
.userContainer h2 {
  margin-top: -15px;
  margin-bottom: 60px;
  padding-left: 10px;
}

.tab-content h2 {
  margin: 40px 0;
}

.userLinks {
  display: none;
}

.dashboardContainer {
  width: 100vw;
  /* margin: 40px auto;
   
    padding:40px 20px; */
}

.headerSection {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr ifr;
}

.headerSection h2 {
  grid-row: 2;
  margin-bottom: 0;
}

.headerSection a {
  justify-self: end;
}

.btd {
  margin: 20px;
  align-self: end;
  grid-row: 3;
}
.userLinks {
  padding: 60px 16px;
  background: var(--lightred);
  width: 240px;
}

.userLinks li {
  display: block;
  padding: 20px;
  cursor: pointer;
}

.userLinks .active {
  color: var(--red);
  border-left: solid 3px var(--red);
  font-weight: bold;
}

.dashboard h2 {
  margin-bottom: 20px;
}
/* .dashboard h3{font-size: 3rem;}
.dashboard h4{font-size: 2.5rem; margin-bottom: 20px;} 
.dashboard h4 {

    margin-bottom: 20px;
}*/

.userPanel {
  background: var(--lightred);
  padding: 10px 15px;
  max-width: 300px;
  display: none;
}

.leftPanel,
.rightPanel {
  display: flex;
  flex-wrap: wrap;
  width: 90%;
  /* padding-top: 80px; */
  margin: auto;
}

/* .rightPanel .dashboardPanel {
  height: 375px;
 } */

.dashboardPanel {
  margin-bottom: 20px;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  background: #fff;
  padding: 20px;
  font-size: 2.1rem;
  width: 100%;
  border-radius: 30px;
  border: solid 1px var(--red);
}
.userDashboard button:not(.mealcardButton) {
  padding: 10px;
  height: fit-content;
  width: fit-content;
  min-width: 148px;
  max-width: fit-content;
  margin: 40px auto 0;
  position: relative;
}

.userDashboard .addRemove button {
  margin: 0 auto;
}

.userDashboard button:hover {
  box-shadow: 0 0 11px var(--green);
  color: var(--nearwhite);
}

.rightPanel .dashboardPanel a,
.leftPanel .dashboardPanel a {
  margin: auto;
  width: fit-content;
}

.userDashboard button:hover a {
  color: var(--nearwhite);
}

.dashboardPanel .flexGrow {
  flex-grow: 1;
}

/* .dashboard p{
    color: var(--red);
    font-weight: bold;
} */

.datesContainer {
  margin: auto auto 20px;
  width: 80%;
  overflow: auto;
}

.myMeals {
  margin-top: 60px;
}

.dashboard h2 {
  border-bottom: solid 1px var(--red);
}

.counterism {
  padding-left: 10px;
  margin-top: auto;
}

.dashboardPref {
  display: flex;
  flex-wrap: wrap;
}

.userDashboard li.preferenceBox {
  margin-right: 20px;
  height: fit-content;
  /* width: 100%; */
  display: flex;
  flex-direction: row;
  color: var(--green);
  background-repeat: no-repeat;
  background-size: 50px;
  background-position: left 10px;
  /* align-items: flex-end;
  justify-content: center; */
  padding: 20px 0 10px 50px;
  text-align: center;
  white-space: nowrap;
  text-transform: capitalize;
  font-size: 2rem;
}
.saveButtons {
  text-align: center;
  padding-bottom: 60px;
}

.saveButtons .nextButton {
  margin: 0 10px;
  display: inline-block;
  width: 100px;
  min-width: 100px;
}

.saveButtons .nextButton[disabled] {
  margin: 0 10px;
  display: inline-block;
  width: 100px;
  min-width: 100px;
  background: var(--lightGrey);
  color: var(--nearwhite);
}

.saveButtons .nextButton.cancel {
  background: var(--green);
}

.planSetup li {
  height: 60px;
  background-size: 60px;
  background-repeat: no-repeat;
  padding-left: 60px;
  place-content: center;
  color: var(--green);
  font-weight: bold;
  display: block;
  font-size: 2rem;
}

.ccsection li {
  color: var(--green);
  font-size: 2.1rem;
}

.ccsection img {
  width: 60px;
  vertical-align: middle;
  margin-right: 20px;
}

.deliveryPanels {
  display: flex;
  gap: 0 60px;
  flex-wrap: wrap;
}

.deliveryPanels .deliveryPanel {
  width: fit-content;
}

.deliveryPanels.delivery .deliveryPanel {
  width: 100%;
}

.deliveryPanel {
  margin: 40px 0;
  background-color: #fff;

  padding: 20px;
  border-radius: 15px;
}

.deliveryPanel h3 {
  margin-bottom: 20px;
}

.deliveryPanel ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 40px;
}

.deliveryPanel li {
  background-color: transparent;
  padding: 20px;
  cursor: pointer;
  width: 180px;
  text-align: center;
  place-content: center;
  margin: 15px 10px;
  border: solid 1px var(--green);
  font-size: 2rem;
}

.deliveryPanel li.active {
  background-color: var(--red);
  color: var(--nearwhite);
}

.dashboardPanel a {
  margin-top: 0;
  text-align: center;
}

[popover] {
  background: var(--nearwhite);
  padding: 20px;
}

/* the dialog's "modal" background */
[popover]::backdrop {
  background: rgba(0, 0, 0, 0.5);
}

.linksPanel {
  font-size: 1.75rem;
  line-height: 5rem;
  background: var(--lightred);
  padding: 0 20px;
  color: var(--green);
}

.adminDash .linksPanel {
  color: var(--nearwhite);
}

.linksPanel a {
  color: var(--nearwhite);
}

.linksPanel dt {
  font-weight: bold;
  border-bottom: solid 1px var(--nearwhite);
}

.linksPanel a:hover {
  color: var(--green);
}

.linksPanel a:active {
  color: var(--green);
}
.adminMainContent {
  margin-top: 0;
  padding-left: 60px;
}

.userData {
  padding: 0;
}
.dashpanels {
  font-size: 2.5rem;
  font-weight: bold;
  color: var(--nearwhite);
  margin-bottom: 40px;
  background-color: var(--green);
  padding: 50px 30px;
  border-radius: 15px;
  text-align: center;
  width: 360px;
}
.dashpanels p {
  line-height: 2rem;
}

.dashpanels span {
  font-size: 3rem;
  line-height: 5rem;
}

.dashboard {
  flex-wrap: wrap;
  display: flex;
  gap: 40px;
}

.dashboardContent {
  margin: auto;
  width: 100%;
}

.userDashboard {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 60px;
  padding-top: 60px;
}

.userDashboard h2 {
  width: 90%;
}
.townPanel {
  background-color: var(--nearwhite);
  border-radius: 15px;
  padding: 40px;
  width: 100%;
  max-width: 600px;
  margin: 40px 0;
  box-shadow: 0 0 1px #000;
  font-size: 2rem;
}

.townPanel h2 {
  margin-bottom: 20px;
  font-size: 2.5rem;
}
.townPanel li {
  font-size: 2rem;
}

.userTable table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
  display: inline-table;
  margin-bottom: 100px;
}

.userTable th,
.userTable td {
  padding: 5px;
}

.userTable th {
  text-align: left;
  font-size: 2.1rem;
  color: var(--green);
}

.userTable tr:nth-child(3) {
  width: 100px;
}

.userTable td {
  font-size: 2rem;
  width: fit-content;
}

.userTable tr {
  background: #fff;
  border-bottom: solid 2px var(--lightGrey);
}

.userTable tr:nth-child(even) {
  background-color: var(--offwhite);
}

.userTable button {
  margin-right: 40px;
  min-width: 100px;
  height: 45px;
  float: right;
  background: transparent;
  color: var(--green);
}

.userList button {
  margin-right: 40px;
  min-width: 100px;
  height: 35px;
  float: right;
  background: var(--red);
  color: var(--nearwhite);
}
.userList .emailLink {
  cursor: pointer;
}

button.emailAll {
  margin-bottom: 50px;
}
.bfDelete {
  display: flex;
  flex-wrap: wrap;
}

.bfDelete p {
  margin-bottom: 30px;
  width: 100%;
}

.bfDelete button {
  justify-self: center;
}

.adminMainContent form {
  align-items: center;
  gap: 20px;
  font-size: 2rem;
}

.panelContainer {
  display: flex;
  gap: 50px;
}

.panelContent {
  box-shadow: 0 0 5px var(--green);
  padding: 20px;
  margin-bottom: 20px;
  width: 30%;
}

.panelContent span {
  font-weight: bold;
  display: inline-flex;
  margin-left: 15px;
}

.userList {
  margin-top: 60px;
}

.userForm form {
  display: flex;
  flex-wrap: wrap;
  align-items: start;
}

.userForm fieldset {
  padding-top: 40px;
  min-height: 560px;
}

/* .userForm .col2{
    width: 30%;
  }

  .userForm .col4{
    width: 20%;
  } */

.userForm .row2 {
  width: 10%;
}
/* 
  .userForm .col1{
    grid-column: 1;
  } */

.userForm textarea {
  resize: none;
  width: 500px;
  height: 100px;
}

.userForm input:not([type="checkbox"]),
.userForm textarea {
  margin: 0 0 20px;
  width: 500px;
  max-width: none;
}

.userForm input[type="checkbox"] {
  width: 20px;
  display: inline;
}

.userForm .prefcheck label {
  display: inline-block;
  width: 200px;
}

.userForm .buttonSection {
  width: 100%;
  place-content: center;
  display: flex;
  padding-bottom: 40px;
}

.buttonSection button {
  margin: 0 20px;
}
.adduser h3 {
  margin-bottom: 20px;
}

.adduser input {
  margin: 0 0 40px;
}

.adduser form {
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
}

.adduser form p {
  color: var(--red);
  position: relative;
}

.buttonGroup {
  width: fit-content;
  margin: auto;
}
.adduser label {
  display: block;
}

.buttonGroup button {
  margin: 0 20px;
}

.errorMessage {
  color: var(--red);
  font-size: 2rem;
  font-weight: bold;
  margin-top: -30px;
}

.orderHistory img {
  width: 200px;
}

.weekOrder {
  border-bottom: solid 2px var(--lightGrey);
  margin-left: 0;
  margin-right: 0;
}

.orderHistory h2 {
  margin-bottom: 0px;
  margin-top: 30px;
}

.orderHistory img[alt] {
  font-size: 1rem;
  vertical-align: top;
}
