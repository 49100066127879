.content {
  width: 100%;
  padding: 0;
  margin: 0;
}

.homeContent {
  width: 100vw;
}

.homeContainer {
  width: 100vw;
  overflow-x: hidden;
  max-width: 1600px;
  margin: auto;
}

.homeContainer button {
  transition: all 0.35s ease-out;
}

.homeContainer button:hover {
  background: var(--green);
}

.hero {
  width: 100%;
  height: calc(68vh - 75px);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 28% 0;
  background-image: url("../../public/images/hero.jpg");
  font-family: youngserif;
  display: grid;
  grid-template-columns: 1vw 1fr 28vw;
  align-items: end;
  justify-content: left;
  padding-bottom: 20px;
  animation: heroSwipe 2s forwards;
  overflow: hidden;
  position: relative;
  font-weight: bold;
  opacity: 0;
}

.hero div {
  margin: 0;
  justify-self: start;
  line-height: 8rem;
  font-family: youngserif;
}

.hero div,
.hero div p {
  margin: 0;
  justify-self: start;
  line-height: 8rem;
  font-family: youngserif;
  font-size: 2em;
}

.hero div {
  margin: 0 0 0px 10px;
  grid-column: 2;
}

.hero div p {
  color: var(--green);
  font-size: 5.6rem;
}

.hero button {
  width: 260px;
  font-size: 2.3rem;
  padding: 10px;
  margin: auto;
  display: block;
  margin: auto;
}

.moremeals {
  overflow-x: hidden;
  min-height: 0;
  max-width: calc(100vw - 60px);
  margin: 50px auto;
  color: var(--green);
  display: flex;
  flex-direction: column;
}

.moremeals article {
  transition: all 1s ease-out;
  display: flex;
  flex-direction: column;
  /* opacity: 0;
    scale: 0; */
  width: 100%;
  margin-bottom: 10px;
  border-radius: 20px;
  /* border: solid 3px var(--beige); */
}
.moremeals h3,
.moremeals h4,
.moremeals p {
  margin: 10px 10px auto;
}

.moremeals .articleClass h4 {
  font-size: 1.5rem;
}

.moremeals article img {
  border-radius: 20px;
  width: 100%;
  justify-self: center;
  object-fit: cover;
  aspect-ratio: 1;
  /* max-width: 400px; */
  margin: auto;
}

.midsection button {
  opacity: 1;
  width: 220px;
}

.midsection {
  text-align: center;
  margin: auto;
  width: fit-content;
  margin: 25px auto;
}

.midsection button {
  margin-bottom: 10px;
}

.moremeals .midsection {
  grid-column: 1/-1;
  margin-top: 1rem;
  /* opacity: 0; */
  transition: all 3s ease-out;
}

.moremeals .midsection button {
}

.moremeals h3 {
  text-transform: uppercase;
}

.moremeals article p {
  margin: 20px 10px 20px;
}

.moremeals p.linkText {
  width: 100%;
}

.how {
  min-height: 0;
  padding-top: 2em;
  background-color: #f6f1e8;
  overflow: hidden;
}

.how .subheading,
.behealthysection .subheading,
.why .subheading {
  animation-delay: 0.3s;
  opacity: 0;
  text-align: center;
}

.behealthysection .subheading {
  padding: 0 20px;
}

.howIcons {
  display: grid;
  grid-template-columns: 70%;
  grid-template-rows: 60px 90px repeat(4, 250px) 50px;
  margin: auto;
  width: fit-content;
  padding-bottom: 80px;
  justify-content: center;
}

.animText .span {
  font-size: 2.8rem;
}

.titleAnim {
  animation: whyIn 0.3s ease 0s forwards;
}

.addPeople,
.meals,
.truck,
.cookit {
  max-width: 300px;
  box-shadow: 2px 18px 23px;
  width: 100%;
  height: fit-content;
  min-height: 250px;
  border-radius: 10%;
  position: relative;
  z-index: 0;
}

.addPeople {
  grid-column: 1;
  grid-row: 2;
  margin-top: 80px;
  justify-self: end;
  transform: rotate(-27deg);
}

.addpeopleP {
  grid-column: 1;
  grid-row: 4;
}

.addpeopleP,
.mealsP,
.truckP,
.cookitP {
  align-self: center;
  opacity: 0;
  background-color: var(--green);
  color: var(--nearwhite);
  padding: 38px;
  border-radius: 20px;
  position: relative;
  text-align: center;
  width: 100%;
  overflow: visible;
  display: flex;
  align-content: center;
  justify-content: center;
  flex-direction: column;
  max-width: 400px;
  z-index: 3;
}

.addpeopleP p,
.mealsP p,
.truckP p,
.cookitP p {
  font-family: youngserif;
}

.addpeopleP .span,
.truckP .span {
  background: var(--nearwhite);
  color: var(--green);

  width: 42px;
  border-radius: 60px;
  text-align: center;
  font-weight: bold;
  position: absolute;
  top: -20px;
  left: 5px;
  border: solid 2px;
}

.mealsP .span,
.cookitP .span {
  background: var(--nearwhite);
  color: var(--green);

  width: 42px;
  border-radius: 60px;
  text-align: center;
  font-weight: bold;
  position: absolute;
  top: -15px;
  right: 5px;
  border: solid 2px;
}

.curveOne {
  grid-column: 1;
  grid-row: 4;
  z-index: 2;
  position: relative;
  left: -33px;
  top: 105px;
  width: 350px;
}

.meals {
  grid-column: 1;
  grid-row: 5;
  position: relative;
  align-self: center;
  transform: rotate(27deg);
  justify-self: start;
}

.mealsP {
  grid-column: 1;
  grid-row: 6;
  justify-self: end;
}

.mealsP p {
  padding-right: 10px;
}

.curveTwo {
  grid-column: 1;
  grid-row: 6;
  position: relative;
  top: 80px;
  left: -42px;
  z-index: 2;
  width: 350px;
  transform: rotateY(180deg);
}

.curveThree {
  grid-column: 1;
  grid-row: 11;
  z-index: 2;
  position: relative;
  left: -62px;
  top: -150px;
  width: 350px;
}

.truck {
  grid-column: 1;
  grid-row: 9;
  align-self: center;
  border-radius: 20px;
  transform: rotate(-27deg);
  justify-self: end;
}

.truckP {
  grid-column: 1;
  grid-row: 10;
}

.truck .svgAnim {
  fill: var(--nearwhite);
}

.cookit {
  grid-column: 1;
  grid-row: 11;
  justify-content: center;
  align-self: center;
  border-radius: 20px;
  transform: rotate(27deg);
  justify-self: start;
}

.cookitP {
  grid-column: 1;
  grid-row: 12;
  justify-self: end;
}

.howIcons h2 {
  align-self: end;
}

.how h2,
.behealthysection h2,
.why h2 {
  margin: 20px 0 0;
  /* opacity: 0; */
  text-align: center;
}

.howIcons h2,
.howIcons .subheading {
  grid-column: 1;
  grid-row: 1;
  margin: 0;

  color: var(--green);
  text-align: center;
}

.howIcons .subheading {
  grid-row: 2;
  align-self: start;
}

.howIcons div svg {
  margin: auto;
}

.howImg {
  border-right: solid 6px var(--green);
}

.how .howmainImg {
  position: relative;
  top: 10px;
}

.overflowSection {
  max-width: 100vw;
  margin: auto;
  overflow-x: visible;
  display: grid;
  grid-template-columns: 1fr;
}

.linkText {
  font-family: youngserif;
  color: var(--green);
  font-size: 2rem;
}

.midadgreen,
.why {
  min-height: 80vh;
  position: relative;
  display: block;
  background: var(--nearwhite);
  transition: all 1s ease 0.1s;
  /* transform: translateX(-99%); */
  margin: 20px 0;
}

.why {
  /* transform: translateX(99%); */
  z-index: 0;
  overflow: hidden;
}

.midadgreen {
  z-index: 1;
  overflow: hidden;
  margin-bottom: 10px;
}

.midadgreen div {
  /* justify-self: end; */
  background: var(--green);
  padding: 3rem 2.5rem;
}

.midadgreen h2 {
  font-weight: normal;
  color: var(--beige);
  margin: 10px 0;
  font-size: 2rem;
  line-height: 3rem;
}

.midadgreen p {
  line-height: 2rem;
  color: var(--nearwhite);
  font-size: 1.6rem;
}

.midasContent h2,
.midasContent p {
  /* transform: translateY(200vh); */
  position: relative;
  z-index: 1;
}

.whyContent h2,
.whyContent div {
  /* transform: translateY(-200vh); */
  margin: 10px 0;
  padding-left: 40px;
  padding: 3rem 2.5rem 0;
  color: var(--nearwhite);
}

.whyContent div:last-child {
  padding-bottom: 100px;
}

.whyContent h3 {
  margin: 0;
  color: var(--nearwhite);
}

img.midasContent {
  /* transform: translateY(-100%); */
  transition: all 6s ease-out;
  /* opacity: 0; */
  object-fit: cover;
  object-position: center center;
  width: 100%;
  height: 100%;
  position: relative;

  z-index: 2;
}

img.whyContent {
  /* transform: translateY(100%); 
     transition: all 6s ease-out;
     opacity: 0;*/
  object-fit: cover;
  object-position: center center;
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 2;
  display: none;
}

.whytext {
  position: relative;
  z-index: 0;
  background: var(--red);
  position: relative;
  top: -15px;
}

/* .midasContent.activate h2,
 .midasContent.activate p,
 .whyContent.activate h2,
 .whyContent.activate div {
     animation: midasAnim 1s ease-out .5s forwards;
 } */

/* img.midasContent.activate {
     animation: midasAnimImg 1.25s ease-out .5s forwards;
 }

 img.whyContent.activate {
     animation: WhyAnimImg 1.25s ease-out 0s forwards;
 } */

.midadgreen p.preamble {
  color: var(--black);
  font-size: 4rem;
  line-height: 5rem;
}

.beHealthy {
  min-height: 0;
  /* opacity: 0; */
  transition: all 0.1s;
  background-color: #f6f1e8;
  color: var(--green);
  padding: 20px 0;
}

/* .behealthysection {
     transition: opacity .5s ease;
 } */

.scroller {
  max-width: 90%;
  margin: auto;
  display: flex;
  justify-content: center;
}

.scroller__inner {
  padding-block: 1rem;
  display: flex;
  flex-wrap: nowrap;
  gap: 1rem;
  width: 90%;
  justify-content: center;
  animation-delay: 0.3s;
}

.scroller[data-animated="true"] {
  overflow: hidden;
  /* -webkit-mask: linear-gradient(
    90deg,
    transparent,
    white 20%,
    white 80%,
    transparent
  );
  mask: linear-gradient(90deg, transparent, white 10%, white 90%, transparent); */
}

.scroller[data-animated="true"] .scroller__inner {
  width: max-content;
  flex-wrap: nowrap;
  animation: scroll 100s forwards linear infinite;
}

.scroller__inner img {
  width: 45vw;
  border-radius: 10px;
}

/* .scroller {
  --_animation-direction: forwards;
  --_animation-duration: 2s;
} */

.instasection {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 35px auto;
  /* min-width: 80vw; */
  margin: auto;
  overflow: hidden;
  background-color: var(--red);
  padding: 20px 10px;
}

.instasection a {
  color: var(--beige);
}

.instaicon {
  width: 77px;
  grid-column: 1;
  grid-row: 1/3;
  justify-self: end;
  position: relative;
}

.instasection h2,
.instasection p {
  grid-column: 2/-1;
  grid-row: 1;
  color: var(--nearwhite);
}

.instasection h2 {
  align-self: start;
  margin: 0;
  transform: translateX(100%);
  opacity: 0;
}

.instasection p {
  align-self: end;
  justify-self: start;
  transform: translateX(100%);
  margin: 0;

  animation-delay: 0.5s;
  opacity: 0;
  grid-row: 2;
}

.instaImages {
  grid-row: 3;
  grid-column: 1/-1;
  display: grid;
  grid-template-columns: 1fr 1fr;
  overflow: hidden;
  justify-self: center;
  width: fit-content;
  justify-items: space-between;
  grid-gap: 10px;
  margin-top: 20px;
}

.instaImages .instaImg {
  opacity: 0;
  width: 100%;
  background: green;
  object-fit: contain;
  max-width: 1200px;
}

.faqsection {
  width: 84%;
  margin: 50px auto;
}

.faqs {
  display: grid;
  grid-template-columns: 1fr;
  margin-bottom: 80px;
}

.faqheader h2 {
  opacity: 0;
  margin-bottom: 10px;
}

.faqsection ul {
  margin: 0;
}

.accordion {
  background-color: var(--nearwhite);
  color: #444;
  cursor: pointer;
  padding: 18px;
  width: 99%;
  border: none;
  text-align: left;
  outline: none;
  transition: all 1.5s ease;
  box-shadow: 5px 5px 10px #ccc;
  font-size: 2rem;
  margin-bottom: 0;
}

.faqsection .active,
.accordion:hover {
  background-color: var(--green);
  color: var(--nearwhite);
}

.accordion::before {
  content: ">";
  font-weight: bold;
  float: left;
  margin-left: 5px;
  margin-right: 19px;

  width: 10px;
  height: 100%;
}

.accordion:hover::before {
  color: var(--nearwhite);
}

.accordion.active::before {
  content: ">";
  color: var(--nearwhite);
  transition: all 0.15s ease;
  transform: rotate(90deg);
  margin-top: -10px;
  transform-origin: left center;
}

.accordion p {
  display: block;
  margin-left: 33px;
}

.panel {
  padding: 0 18px;
  background-color: white;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
  width: 90%;
  padding: 0px;
  margin-bottom: 10px;
}

@keyframes moremeals {
  to {
    opacity: 1;
    scale: 1;
  }
}

@keyframes whyIn {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }

  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes midasAnim {
  to {
    transform: translate(0px);
    opacity: 1;
  }
}

@keyframes midasAnimImg {
  0% {
    transform: translateY(-100%);
    opacity: 0;
  }

  50% {
    transform: translateY(-50%);
    opacity: 0;
  }

  100% {
    transform: translateY(0%);
    opacity: 1;
  }
}

@keyframes WhyAnimImg {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }

  50% {
    transform: translateY(50%);
    opacity: 0;
  }

  100% {
    transform: translateY(0%);
    opacity: 1;
  }
}

@keyframes scroll {
  to {
    transform: translate(calc(-50% - 0.5rem));
  }
}

@keyframes instaResize {
  to {
    opacity: 1;
  }
}

@media screen and (min-width: 400px) {
  .instasection p {
    align-self: center;
  }
}

@media screen and (min-width: 800px) {
  .overflowSection {
    width: 80vw;
  }

  .hero {
    width: 100%;
    height: calc(100vh - 75px);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 0 70%;
    background-image: url("../../public/images/hero.jpg");
    font-family: youngserif;
    display: grid;
    grid-template-columns: 1vw 1fr 28vw;
    align-items: end;
    justify-content: left;
    padding-bottom: 20px;
    /* animation: heroSwipe 2s forwards; */
    overflow: hidden;
    position: relative;
    font-weight: bold;
    /* opacity: 0; */
    border-radius: 10px;
  }

  .hero div {
    margin: 0 0 0px 50px;
    grid-column: 2;
  }

  .hero div p {
    color: var(--green);
    font-size: 9.6rem;
  }

  .hero div {
    margin: 0 0 0px 50px;
    grid-column: 2;
  }

  .hero div p {
    color: var(--green);
    font-size: 8.6rem;
  }

  .overflowSection {
    max-width: 90%;
    margin: auto;
    overflow-x: visible;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    width: 100%;
    grid-gap: 25px;
    grid-column: 1/-1;
  }

  .moremeals {
    overflow-x: hidden;
    min-height: 0;
    max-width: calc(100vw - 60px);
    margin: 50px auto;
    color: var(--green);
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }

  .moremeals h3 {
    text-transform: uppercase;
  }

  .moremeals .midsection {
    grid-column: 1/-1;
    margin-top: 1em;
    /* opacity: 0; */
    transition: all 3s ease-out;
  }

  .moremeals article {
    display: block;
  }

  .how {
    border-radius: 10px;
    min-height: 0;
    padding-top: 2em;
    background-color: #f6f1e8;
  }

  .how .subheading,
  .behealthysection .subheading,
  .why .subheading {
    animation-delay: 0.5s;
    opacity: 0;
    text-align: center;
  }

  .whytext {
    position: relative;
    z-index: 0;
    background: var(--red);
    position: relative;
    top: 0;
    border-radius: 0 10px 10px 0;
  }

  .howIcons {
    display: grid;
    grid-template-columns: 200px 250px 200px;
    grid-template-rows: 60px 90px repeat(4, 350px) 50px;
    margin: auto;
    width: 75%;
    padding-bottom: 80px;
  }

  .titleAnim {
    animation: whyIn 0.3s ease 0s forwards;
  }

  .addPeople,
  .meals,
  .truck,
  .cookit {
    max-width: 300px;
    box-shadow: 2px 18px 23px;
    width: 100%;
    height: fit-content;
    min-height: 250px;
    border-radius: 10%;
    position: relative;
    z-index: 0;
  }

  .addPeople {
    grid-column: 1;
    margin-top: 20px;
    justify-self: end;
    transform: rotate(-27deg);
  }

  .addpeopleP {
    grid-column: 2;
    grid-row: 3;
  }

  .addpeopleP,
  .mealsP,
  .truckP,
  .cookitP {
    align-self: center;
    opacity: 0;
    background-color: var(--green);
    color: var(--nearwhite);
    padding: 38px;
    border-radius: 20px;
    height: fit-content;
    position: relative;
    text-align: center;
    width: 100%;
    overflow: visible;
    display: flex;
    align-content: center;
    justify-content: center;
    flex-direction: column;
    max-width: 400px;
    z-index: 3;
  }

  .addpeopleP p,
  .mealsP p,
  .truckP p,
  .cookitP p {
    font-family: youngserif;
  }

  .addpeopleP .span,
  .truckP .span {
    background: var(--nearwhite);
    color: var(--green);

    width: 42px;
    border-radius: 60px;
    text-align: center;
    font-weight: bold;
    position: absolute;
    top: -20px;
    left: -10px;
    border: solid 2px;
  }

  .mealsP .span,
  .cookitP .span {
    background: var(--nearwhite);
    color: var(--green);

    width: 42px;
    border-radius: 60px;
    text-align: center;
    font-weight: bold;
    position: absolute;
    top: -15px;
    right: -10px;
    border: solid 2px;
  }

  .curveOne {
    grid-column: 3;
    grid-row: 3;
    z-index: 2;
    position: relative;
    left: -133px;
    top: 105px;
    width: 350px;
  }

  .meals {
    grid-column: 3;
    grid-row: 4;
    position: relative;
    align-self: center;
    transform: rotate(27deg);
    justify-self: start;
  }

  .mealsP {
    grid-column: 2;
    grid-row: 4;
    justify-self: end;
  }

  .mealsP p {
    padding-right: 10px;
  }

  .curveTwo {
    grid-column: 1;
    grid-row: 4;
    position: relative;
    top: 147px;
    left: -24px;
    z-index: 2;
    width: 350px;
    transform: rotateY(180deg);
  }

  .curveThree {
    grid-column: 3;
    grid-row: 5;
    z-index: 2;
    position: relative;
    left: -133px;
    top: 127px;
    width: 350px;
  }

  .truck {
    grid-column: 1;
    grid-row: 5;
    align-self: center;
    border-radius: 20px;
    transform: rotate(-27deg);
    justify-self: end;
  }

  .truckP {
    grid-column: 2;
    grid-row: 5;
  }

  .truck .svgAnim {
    fill: var(--nearwhite);
  }

  .cookit {
    grid-column: 3;
    grid-row: 6;
    justify-content: center;
    align-self: center;
    border-radius: 20px;
    transform: rotate(27deg);
    justify-self: start;
  }

  .cookitP {
    grid-column: 2;
    grid-row: 6;
    justify-self: end;
  }

  .why {
    /* transform: translateX(99%); */
  }

  .midadgreen div {
    /* justify-self: end;
    max-width: calc(90rem / 2 + 55px); */
    padding: 3rem 2.5rem;
  }

  .midadgreen h2 {
    font-weight: normal;
    color: var(--beige);
    margin: 10px 0;
    font-size: 3.25rem;
    line-height: 5rem;
  }

  .midadgreen p {
    line-height: 3.5rem;
    color: var(--nearwhite);
    font-size: 2rem;
  }

  .midasContent h2,
  .midasContent p {
    /* transform: translateY(200vh); */
  }

  .whyContent h2,
  .whyContent div {
    /* transform: translateY(-200vh); */
    margin: 10px 0;
    padding-left: 40px;
    padding: 3rem 2.5rem 0;
    color: var(--nearwhite);
    font-size: 1.8em;
  }

  .whyContent div:last-child {
    padding-bottom: 100px;
  }

  .whyContent h3 {
    margin: 0;
  }

  img.midasContent {
    /* transform: translateY(-100%); */
    transition: all 3s ease-out;
    /* opacity: 0; */
    object-fit: cover;
    object-position: top left;
    width: 100%;
    border-radius: 0 10px 0 0;
  }

  img.whyContent {
    /* transform: translateY(50%);  */
    transition: all 3s ease-out;
    /* opacity: 0; */
    object-fit: cover;
    object-position: center center;
    width: 100%;
    height: 100%;
    border-radius: 10px 0 0 0;
  }

  /* .midasContent.activate h2,
.midasContent.activate p,
.whyContent.activate h2,
.whyContent.activate div {
    animation: midasAnim .25s ease-out .1s forwards;
} */

  /* img.midasContent.activate {
    animation: midasAnimImg .5s ease-out .1s forwards;
} */

  img.whyContent.activate {
    /* animation: WhyAnimImg 1.25s ease-out 1s forwards; */
  }

  .whyContent img {
    width: 75px;
    vertical-align: middle;
    margin-right: 10px;
  }

  .midadgreen p.preamble {
    /* color: var(--nearwhite); */
  }

  .scroller__inner img {
    width: 25vw;
    border-radius: 10px;
    max-width: 200px;
  }

  .instasection {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 35px 1fr;
  }

  .instasubheading a {
    color: var(--nearwhite);
    text-decoration: underline;
  }

  .instasubheading a:hover {
    color: var(--black);
    text-decoration: none;
  }

  .instasection p {
    align-self: self-start;
  }

  .faqsection {
    width: 90%;
    margin: 100px auto auto;
  }

  .faqs {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}

.message-container {
  margin-left: 50px;
}

@media screen and (min-width: 720px) {
  .instaImages {
    grid-template-columns: repeat(auto-fit, minmax(50px, 1fr));
  }
}

@media screen and (min-width: 800px) {
  .howIcons h2,
  .howIcons .subheading {
    grid-column: 2;
  }
}

@media screen and (min-width: 1024px) {
  img.whyContent {
    display: block;
  }

  .faqsection {
    width: 90%;
    margin: 50px auto;
  }
  .midadgreen,
  .why {
    min-height: 80vh;

    display: grid;
    grid-template-columns: 50% 50%;
    overflow: hidden;
    transition: all 1s ease 0.1s;

    max-width: 1750px;
    margin: 20px auto;
    border-radius: 10px;
  }

  .howIcons {
    display: grid;
    grid-template-columns: repeat(3, minmax(250px, 1fr));
    grid-template-rows: 60px 90px repeat(4, 350px) 50px;
    margin: auto;
    width: 75%;
    padding-bottom: 80px;
  }
}

@media screen and (min-width: 1024px) {
  .curveTwo {
    grid-column: 1;
    grid-row: 4;
    position: relative;
    top: 147px;
    left: 60%;
    z-index: 2;
    width: 350px;
    transform: rotateY(180deg);
  }
}

@media screen and (min-width: 1700px) {
  /* .scroller {
            max-width: 90%;
        } */
}

@media screen and (min-width: 1820px) {
  /* .scroller{
            max-width: 90%;
        } */
  .scroller__inner {
    max-width: 99%;
  }
}

@media screen and (min-width: 2048px) {
  .howIcons {
    display: grid;
    grid-template-columns: repeat(3, minmax(250px, 1fr));
    grid-template-rows: 60px 90px repeat(4, 350px) 50px;
    margin: auto;
    width: 45%;
    padding-bottom: 80px;
  }
}

@keyframes heroSwipe {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
