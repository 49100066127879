.mealsContainer {
  width: 100vw;
  /* max-width: 1340px; */
  margin: 10px auto;
}

.themeals h1 {
  width: 100%;
  text-align: center;
  /* border-bottom: 1px solid #000;
    line-height: 0.1em; */
  margin: 10px 0 60px;
}

.themeals h1 span {
  background: var(--nearwhite);
  padding: 0 10px;
}

.dashboardContainer .mealsContainer {
  width: 100%;
}

.filterContainer {
  margin-bottom: 25px;
}

.mealList {
  width: 100%;
  max-width: 1900px;
  margin: 40px auto;
  display: flex;
  flex-wrap: wrap;
  gap: 25px;
  justify-content: center;
}

.summaryDiv .mealList {
  margin: 0;
}

.mealCard img {
  border-radius: 12px 12px 0 0;
}

.mealCard {
  display: grid;
  width: 100%;
  background-color: var(--nearwhite);
  grid-template-columns: 50% 50%;

  overflow: hidden;
  grid-template-rows: 1fr;
  box-shadow: 0 4px 9px rgba(0, 0, 0, 0.4);
  border-radius: 12px;
}

.imgContainer img {
  transition: transform 0.5s;
}

.mealsOverlay {
  position: absolute;
  background: rgba(0, 0, 0, 0.3);
  color: var(--nearwhite);
  width: 100%;
  height: 100%;
  place-content: center;
  text-align: center;
  opacity: 0;
  transition: all 0.5s ease-in-out;
  font-size: 2rem;
  cursor: pointer;
  z-index: 2;
}

.imgContainer:hover img {
  transform: scale(1.1);
}

.imgContainer:hover .mealsOverlay {
  opacity: 1;
}

@keyframes fadeInOut {
  0% {
    opacity: 0;
    display: none;
  }

  50% {
    opacity: 0.5;
    display: block;
  }

  100% {
    opacity: 1;
    display: block;
  }
}

.mealCard.gourmet {
  background-color: var(--beige);
}

.mealCard.addactive.gourmet {
  background-color: var(--green);
}

.mealDetails h4 {
  margin: 0;
  /* font-size: 1.5rem; */
}

.mealCard.addactive .mealDetails p {
  color: var(--beige);
}

.mealCard .imgContainer {
  /* height: 195px; */
  /* overflow: hidden; */
  /* order: 5; */
  /* 
    max-width: 100%; */
  width: 100%;
  position: relative;
  grid-column: 2;
}

.imgContainer img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: top center;
}

.mealCard .mealContent {
  display: flex;
  flex-direction: column;
}

.mealDetails {
  padding: 10px 10px 0;
  height: fit-content;
}

.mealDetails h2 {
  white-space: nowrap;
}

.mealDetails p {
  font-size: 1.6rem;
  color: var(--red);
  font-weight: normal;
}

.mealimgContainer {
  width: 100%;
}

.mealimgContainer img {
  height: auto;
  width: 100%;
  object-fit: cover;
  object-position: top center;
  height: 250px;
}

.mealCard ul {
  margin: auto 0 10px;
  padding: 0 0 0 10px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.mealtypes ul li {
  font-size: 2rem;
  display: inline;
}

.mealtypes ul li:not(:last-child)::after {
  content: ",";
  margin-right: 10px;
}

.mealCard ul li {
  color: var(--green);
  text-transform: capitalize;
  /* padding-left: 32px;
    padding-top: 3px;
    background-repeat: no-repeat;
    background-size: 30px;
    background-position: left;
    height: 27px;
    margin-bottom: 10px; */
  width: fit-content;

  font-size: 1.3rem;
  border: none;
  /* border-radius: 20px; */
}

.mealCard.gourmet ul li {
  color: var(--red);
}

.mealImages {
  display: flex;
  position: fixed;
  bottom: 0;
  background: #fff;
  width: 100%;
  left: 0;
  padding: 20px;
  z-index: 99;
  justify-content: center;
  flex-wrap: wrap;
  box-shadow: 0px -0px 6px #333;
}

.mealImages .nextButton {
  margin: 0 70px;
}

.chosenmeals {
  display: flex;

  overflow: hidden;
  width: fit-content;
}

.selectedText {
  color: var(--green);
  width: 100%;
  text-align: center;
}

.userDashboardAdmin .mealList {
  padding: 0 20px;
}

.chosenmeal {
  width: 100px;

  background: var(--lightGrey);
  margin-right: 10px;
}

.addRemove {
  display: flex;
  flex-direction: row;
  margin: auto auto 40px;
  border: solid 1px var(--lightGrey);
  border-radius: 47px;
  position: relative;
  width: 90%;
  left: 0;
  height: 35px;
  padding: 3px 11px;
}

.addRemove p:not(.counter) {
  cursor: pointer;
}

.addRemove p::selection {
  background: transparent;
}

.addRemove button {
  position: absolute;
  width: 95%;
  left: 0px;
  top: 0px;
  height: 40px;
  border-radius: 47px;
  transition: all 0.5s ease;
  opacity: 1;
  padding: 0;
  cursor: pointer;
  min-width: 0;
  text-align: center;
  margin: auto;
}

.userDashboardAdmin .addRemove button {
  position: absolute;
  width: 100%;
  left: 0px;
  top: 0px;
  height: 40px;
  border-radius: 47px;
  transition: all 0.5s ease;
  opacity: 1;
  padding: 0;
  cursor: pointer;
  min-width: 0;
}

.addRemove button:hover {
  background-color: var(--green);
  color: var(--nearwhite);
}

.addRemove button.hide {
  display: none;
}

.addRemove button.disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.addRemove p:last-child {
  margin-left: auto;
}

.addactive {
  color: var(--offwhite);
  background: var(--green);
}

.mealCard.addactive h4,
.mealCard.addactive p,
.mealCard.addactive ul li {
  color: var(--offwhite);
}

.mealCard .counter {
  text-align: center;
  width: 100%;
}

.filters {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  gap: 10px;
  justify-content: start;
  overflow: auto;
  padding-bottom: 10px;
}

li.filterbutton {
  background-size: 35px;
  background-repeat: no-repeat;
  font-size: 1.6rem;
  border: solid 2px var(--green);
  border-radius: 50px;
  padding: 8px 10px 8px 37px;
  cursor: pointer;
  background-position: center left;
  text-transform: capitalize;
  width: fit-content;
  white-space: nowrap;
}

li.filterbutton.active {
  background-color: var(--green);
  color: var(--nearwhite);
}

.filterbutton.clearfilter {
  background-image: url("../../public/images/icons/clearfilter.png");
}

.filterbutton.familyfriendly {
  background-image: url("../../public/images/icons/familyfriendly.png");
}

.filterbutton.kidfriendly {
  background-image: url("../../public/images/icons/kidfriendly.png");
}

.filterbutton.vegetarian {
  background-image: url(../../public/images/icons/vegetarian.png);
}

.filterbutton.lowcarb {
  background-image: url(../../public/images/icons/lowcarb.png);
}

.filterbutton.pescatarian {
  background-image: url(../../public/images/icons/pescatarian.png);
}

.filterbutton.caloriesmart {
  background-image: url(../../public/images/icons/lowcalorie.png);
}

.filterbutton.classics {
  background-image: url(../../public/images/icons/classics.png);
}

.filterbutton.guiltfree {
  background-image: url(../../public/images/icons/guiltfree.png);
}

.filterbutton.chicken {
  background-image: url(../../public/images/icons/chicken.png);
}

.filterbutton.meat {
  background-image: url(../../public/images/icons/meat.png);
}

.filterbutton.gourmet {
  background-image: url(../../public/images/icons/gourmet.png);
}

.filterbutton.breakfast {
  background-image: url(../../public/images/icons/breakfast.png);
}

.mealsBottom {
  display: flex;
  flex-wrap: wrap;
  margin-top: auto;
  padding: 10px;
}

.mealsBottomDash {
  display: flex;
  flex-wrap: wrap;
  margin-top: auto;
  flex-direction: column;
}

.mealsBottom button,
.mealsBottomDash button {
  margin-left: auto;
  margin-bottom: 15px;
  margin-right: 20px;
}

.mealsBottom.mealsCount p {
  color: var(--red);
  margin: auto;
  font-weight: bold;
}

.modalBody li.filterbutton.clearfilter,
.modalBody li.filterbutton.clearfilter.active {
  background-image: url(../../public/images/icons/selectmeal.png);

  background-color: var(--green);
  color: var(--nearwhite);
}

.contentContainer {
  border: none;
  border-radius: 0;
  flex-grow: 1;
  /* width:48%; */
  padding-bottom: 0;
  grid-column: 1;
  grid-row: 1;
  display: flex;
  flex-direction: column;
}
.mealCard.expanded,
.dashboardContainer .mealCard.expanded {
  max-width: 620px;
}

.expdata {
  display: none;
  grid-template-columns: 60% 40%;
  padding-left: 10px;
}

.expanded .expdata {
  display: grid;
}

.expLeft {
  height: 100%;
}

.expRight h4 {
  background: var(--red);
  color: var(--nearwhite);
  font-size: 1.55rem;
  padding: 5px;
}

.mealCard .expRight ul li {
  width: 100%;
}

.mealCard .expRight ul {
  display: list-item;
  list-style: none;
}

.mealCard .expRight ul li {
  display: block;
  padding: 0;
}

.mealsList {
  padding-bottom: 40px;
}

@keyframes modalTransitionIn {
  0% {
    transform: translateY(120vh);
  }

  100% {
    transform: translateY(0vh);
  }
}

@keyframes fsmodalTransitionIn {
  0% {
    transform: translateY(120vh);
  }

  100% {
    transform: translateY(5vh);
  }
}

@keyframes modalTransitionOut {
  0% {
    transform: translateY(30vh);
  }

  100% {
    transform: translateY(120vh);
  }
}
