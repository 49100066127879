.formElements {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0px 40px;
}

.deliver {
  display: flex;
  flex-wrap: wrap;
}

.deliver button {
  flex-grow: 1;
  width: 300px;
  max-width: 300px;
}

.deliver textarea {
  width: 80vw;
  height: 150px;
  margin-bottom: 40px;
  max-width: 800px;
  font-size: 2.5rem;
}

.deliver p {
  font-weight: bold;
  text-align: center;
}

.deliveryDay p {
  margin-top: 50px;
}

.deliver .errorContainer,
.paymentForm .errorContainer {
  position: relative;
  width: 100%;
  max-width: 500px;
}

.deliveryBox {
  background: var(--green);
  padding: 20px;
  color: var(--offwhite);
  border-radius: 30px;
  margin: 40px 0;
  width: fit-content;
}

.deliveryBox button {
  margin-top: 20px;
}

.deliveryBox span {
  color: var(--beige);
}
