

.howitworks h1{
    place-content: center center;
    text-align: center;
    display: flex;
    flex-direction: column;
    font-size: 3em;
    position: relative;
    z-index: 1;
    text-shadow: 0 0 2px var(--green);
}


.screen0, .screen1, .screen2, .screen3, .screen4, .screen5{
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: sticky;
    top: 0;
    place-content: center center;
    height: 95vh;
    margin:  auto;
}
 .svgSectionH{

    margin-top: 20px;
    justify-self: center;
    transform: rotate(-27deg);
    max-width: 300px;
    box-shadow: 2px 18px 23px;
    width: 100%;
    height: fit-content;
    min-height: 250px;
    border-radius: 10%;
    position: relative;
    margin: 0 auto;
}

.instro{
    align-self: center;
        background-color: var(--green);
        color: var(--nearwhite);
        padding: 38px;
        border-radius: 20px;
        height: -moz-fit-content;
        height: fit-content;
        position: relative;
        text-align: center;
        width: 95%;
        overflow: visible;
        display: flex;
        align-content: center;
        justify-content: center;
        flex-direction: column;
        max-width: 90%;
        z-index: 3;

}


.instro span{
    background: var(--nearwhite);
        color: var(--green);
        width: 42px;
        border-radius: 60px;
        text-align: center;
        font-weight: bold;
        font-size: 2rem;
        position: absolute;
        top: -20px;
        left: -10px;
        border: solid 2px;
}

.screen0{
    background-image: url('../../public/images/selectmeal.png');
    background-repeat: no-repeat;
    background-position: 40% center;
    background-blend-mode: screen;
    background-color: rgba(255,255,255,.75);
}

.screen0 img{
        width: 20vw;
        place-content: center;
        z-index: 0;
        position: absolute;
        left: 34vw;
        z-index: 0;
        opacity: .5;
        transform: rotate(-45deg);
}

.screen0 p{
    font-weight: bold;
    margin-top: -20px;
    font-size: 1.5em;
    position: relative;
    z-index: 1;
    padding: 10px;
    text-shadow: 0 0 2px #000;
}

.screen1{
    background:var(--offwhite);
}
.screen2 {
    background: var(--offwhite)
}
.screen3 {
    background: var(--offwhite)
}
.screen4 {
    background: var(--offwhite)
}

.screen0 p{
    place-content: center center;
    text-align: center;
     align-items: center;
  

}